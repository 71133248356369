import React from 'react';
import {Image} from "react-bootstrap";

const MobileGptTaro = () => {
    return (
        <div className="d-flex flex-column align-content-center mobMain d-flex flex-column"
             style={{
                 background:"#f4fbf6",
                 height:"100vh",
                 width:"100vw"
             }}>
            <div
                className="d-flex flex-column align-content-end justify-content-center"
                style={{
                    borderStyle:"solid",
                    borderColor:"lightgray",
                    borderRadius:10,
                    marginLeft:40,
                    marginRight:40,
                    marginTop:10,
                    background:"white",
                    padding:20,
                }}>
                <Image style={{marginBottom:3, borderRadius:90}} alt="Гадание таро по видео" width={140} height={140} src={`${process.env.REACT_APP_STAT_URL}/logo.jpg`}/>
                <h4 className="mt-2">
                    Значение таро, значение карт, гадание🔮
                </h4>
                <div className="mt-2" style={{color:"gray"}}>
                    @gpt_taro_bot
                </div>
                <div className="mt-2 mb-1">
                    Этот бот объяснит значение таро раскладов на картах. Вы пишите тему гадания и какие карты вам выпали, а в ответ получаете подробное значение карт для вашей ситуации. Перейдите в Telegram, чтобы начать гадать.
                </div>
                <a
                    href={"tg://resolve?domain=gpt_taro_bot"}
                    style={{
                        alignContent:"center",
                        background:"blue",
                        color:"white",
                        padding:10,
                        marginTop:10,borderRadius:10
                    }}
                >
                    Перейти в Telegram
                </a>
            </div>

        </div>


    );
};

export default MobileGptTaro;