import {makeAutoObservable} from "mobx";

export default class WitchRegistrationStore {

    email: string
    userName: string
    password: string
    secondPassword: string
    accountPicNumber: number
    description: string
    pathLink: string

    constructor() {
        this.email = ''
        this.userName = ''
        this.password = ''
        this.secondPassword = ''
        this.accountPicNumber = 1
        this.description = ''
        this.pathLink =''

        makeAutoObservable(this)
    }

    setEmail(email: string) {
        this.email = email
    }

    setUserName(userName: string) {
        this.userName = userName
    }

    setPassword(password: string) {
        this.password = password
    }

    setSecondPassword(secondPassword: string) {
        this.secondPassword = secondPassword
    }

    setAccountPicNumber(accountPicNumber: number) {
        this.accountPicNumber = accountPicNumber
    }

    setDescription(description: string) {
        this.description = description
    }

    setPathLink(pathLink: string) {
        this.pathLink = pathLink
    }

    getEmail() {
        return this.email
    }

    getUserName() {
        return this.userName
    }

    getPassword() {
        return this.password
    }

    getSecondPassword() {
        return this.secondPassword
    }

    getAccountPicNumber() {
        return this.accountPicNumber
    }

    getDescription() {
        return this.secondPassword
    }

    getPathLink() {
        return this.pathLink
    }




}