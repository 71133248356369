import React, {useContext, useEffect} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const Width =observer(() => {
    const {width} = useContext(Context)

    const updateWidth = () => {width.setWidth(window.innerWidth)}



    useEffect(() => {

        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth);

    }, [width])
    return (
        <div>

        </div>
    );
});

export default Width;