import React from 'react';

const LightGrayLine = () => {
    return (
        <div className="  d-flex flex-wrap justify-content-around bottom mt-3"
             style={{
                 borderTopStyle:"solid",
                 borderTopWidth:"1px",
                 borderColor:"lightgray"}}>
        </div>
    );
};

export default LightGrayLine;