import React, {useEffect, useRef} from 'react';
import './VideoPlayer.css'

const VideoPlayer:React.FC<{stream: MediaStream}> = ({stream}) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const assignStream=()=>{
        if(videoRef.current){
            videoRef.current.srcObject = stream
            console.log('stream updated in VideoPlayer')
        }else {console.log('no current stream')}
    }

    useEffect(()=>{
            assignStream()
        },
        [stream])

    return (
        <video onClick={()=>assignStream()} className="vpl" autoPlay={true} ref={videoRef}/>
    );
};

export default VideoPlayer;