import {
    ADMIN_ROUTE,
    LOGIN_ROUTE,
    REGISTRATION_ROUTE,
    WITCH_ROUTE,
    MAIN_ROUTE,
    USER_ACCOUNT_ROUTE,
    WHEEL_ROUTE, STUDIO_ROUTE, PRICE_ROUTE, PRIVARE_GADANIE_ROUTE, MOBILE_ROUTE, GPT_TARO_ROUTE
} from "./utils/consts";



import Witch from "./Pages/witch/witch";
import Auth from "./Pages/auth";
import Steam from "./Pages/steam";
import User_accaunt from "./Pages/user_accaunt/user_accaunt";
import registration from "./Pages/registration";
import Wheel from "./Pages/wheel";
import admin from "./Pages/admin";
import Studio from "./Pages/studio";
import price from "./Pages/price";
import PrivateGadanie from "./Pages/privateGadanie";
import Mobile from "./Pages/mobile";
import MobilePrivateGadanie from "./components/mobile/MobilePrivateGadanie/MobilePrivateGadanie";
import MobilePrivateFirst from "./Pages/mobilePrivateFirst";
import GptTaro from "./Pages/gpt-taro";



export const authRouts = [
    {
        path:  ADMIN_ROUTE,
        Element: admin
    },

    {
        path:  USER_ACCOUNT_ROUTE,
        Element: User_accaunt
    }
    ,
    {
        path:  WHEEL_ROUTE,
        Element: Wheel
    }


]

export const publicRouts = [

    {
        path:  LOGIN_ROUTE,
        Element: Auth
    },

    {
        path:  REGISTRATION_ROUTE,
        Element: registration
    },


    {
        path:  PRICE_ROUTE,
        Element: price
    }
]


export const whitchRouts = [

    {
        path:  WITCH_ROUTE +'/:witchNick',
        Element: Witch
    },

    {
        path:  STUDIO_ROUTE,
        Element: Studio
    }

]
export const mobileRouts = [
    {
        path:  MAIN_ROUTE,
        Element: GptTaro
    },

    {
        path:  MAIN_ROUTE + '/:witchNick',
        Element: GptTaro
    },
    {
        path:  MOBILE_ROUTE + '/:witchNick',
        Element: GptTaro
    },

    {
        path:  PRIVARE_GADANIE_ROUTE + '/:privateRoomId',
        Element: MobilePrivateFirst
    }
]

export const gptRouts = [

    {
        path:  GPT_TARO_ROUTE,
        Element: GptTaro
    }
]


