import React, {useContext} from 'react';
import {Image} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const AccountCircul = observer(() => {
    const {user} = useContext(Context)
    return (
        <div
            style={{borderRadius:90, marginLeft:10, cursor:"pointer"}}>
            <Image src={user.getUserPic()}
                   alt="Пользователь гадания таро по видео"
                   style={{width:39,
                       height:39, zIndex:3
                   }}/>
            <Image src={`${process.env.REACT_APP_STAT_URL}/circul.png`}
                   alt="Круг пользователя гадания таро по видео"
                   style={{ zIndex:1, marginLeft:-43}}
                   height={50}
                   width={50}
            />
        </div>
    );
});

export default AccountCircul;