import React from 'react';
import MessageInChat from "./MessageInChat";
import MainChatInput from "./MainChatInput";



const MainChat = () => {
    return (
        <div className="d-flex flex-column justify-content-start align-items-start mt-4 ml-3 mr-4 border"
             style={{width:'100%', height:450, borderStyle:"solid", minWidth:360, maxWidth:720}}>

            <MainChatInput/>
            <MessageInChat reversed={true}/>
        </div>

    );
};

export default MainChat;