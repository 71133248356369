import React from 'react';
import {Route, Routes, Navigate} from "react-router-dom";
import {authRouts, gptRouts, mobileRouts, publicRouts, whitchRouts} from "../routes";
import {IONProvider} from "../store/IONStore";
import NavBar from "./NavBar/NavBar";
import Footer from "./Footer";
import Width from "./Width/Width";



const AppRouter = () => {

    return (
        <Routes>

            {authRouts.map(
                ({path,Element})=>{
                    return <Route key={path} path={path} element={
                        <div>
                            <NavBar/>
                            <Element/>
                            <Footer/>
                            <Width/>
                        </div>
                    } />
                }
            ) }

            {publicRouts.map(
                ({path,Element})=>{
                    return <Route key={path} path={path} element={
                        <div>
                            <NavBar/>
                            <Element/>
                            <Footer/>
                            <Width/>
                        </div>
                    } />
                }
            ) }


            {mobileRouts.map(
                ({path,Element})=>{
                    return <Route key={path} path={path} element={
                        <div>
                            <Element/>
                        </div>

                    } />
                }
            ) }



            {gptRouts.map(
                ({path,Element})=>{
                    return <Route key={path} path={path} element={
                        <div>
                            <Element/>
                        </div>

                    } />
                }
            ) }

                {whitchRouts.map(
                    ({path,Element})=>{
                        return <Route key={path} path={path} element={
                            <IONProvider>
                                <div>
                                    <NavBar/>
                                    <Element/>
                                    <Footer/>
                                    <Width/>
                                </div>
                            </IONProvider>
                        } />
                    }
                ) }


            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />

        </Routes>
    )}

export default AppRouter;
