import $api from "../http/forTraining";
import {AxiosResponse, HttpStatusCode} from "axios";
import {ICoinsShort, IDiamonds, IExchangeResponse, IUserAccountPicNumber, IUserWithName} from "../models/IUser";

export default class UserService{

    static async changeUserName(userName: string):Promise<AxiosResponse<IUserWithName>>{
        return $api.post('/users/changeUserName', {userName})
    }


    static async changeIsFirstTimeAfterActivation():Promise<AxiosResponse>{
        return $api.post('/users/changeIsFirstTimeAfterActivation')
    }

    static async changeAccountPicNumber(accountPicNumber: number):Promise<AxiosResponse<IUserAccountPicNumber>>{
        return $api.post('/users/changeAccountPicNumber', {accountPicNumber})
    }

    static async changePassword(password:string, newPassword1:string, newPassword2:string):Promise<AxiosResponse<IUserWithName>>{
        return $api.post('/users/changeUserPassword', {password, newPassword1,newPassword2})
    }

    static async getDiamonds(limit:number, page:number):Promise<AxiosResponse<HttpStatusCode.Accepted>>{
        return $api.post('/diamonds/getdiamondshistory', {limit,page})
    }

    static async getDiamondsTransactionsCount():Promise<AxiosResponse<number>>{
        return $api.get('/diamonds/get_diamonds_transacions_count')
    }

    static async getCoins(limit:number, page:number):Promise<AxiosResponse<HttpStatusCode.Accepted>>{
        return $api.post('coins/getCoinsHistory', {limit,page})
    }

    static async getCoinsTransactionsCount():Promise<AxiosResponse<number>>{
        return $api.get('/coins/get_coins_transacions_count')
    }

    static async coinsTransactions(transaction:number, cause:string):Promise<AxiosResponse<ICoinsShort>>{
        return $api.post('/coins/transactionCoins', {transaction, cause})
    }

    static async paymentsCreate(value:number, description:string, linkToPath:string):Promise<AxiosResponse<string>>{
        return $api.post('payments/create', {value:value, description:description, linkToPath:linkToPath})
    }

    static async exchangeDiamonds(transaction:number, cause:string):Promise<AxiosResponse<IExchangeResponse>>{
        return $api.post('/diamonds/exchangeDiamonds', {transaction, cause})
    }

    static async getCoinsCount():Promise<AxiosResponse<{ coins_count: number }>>{
        return $api.get('/coins/get_coins_count')
    }

    static async getDiamondsCount():Promise<AxiosResponse<{ diamons_count: number }>>{
        return $api.get('/diamonds/get_diamonds_count')
    }

    static async deleteAccount():Promise<AxiosResponse<IDiamonds[]>>{
        return $api.delete('/users/deleteAccount')
    }


    static async getPendingTasks():Promise<AxiosResponse<HttpStatusCode.Accepted>>{
        return $api.get('witch-tasks/getTasks')
    }

    static async getHistoryOfCompleatedTasks( limit:number, page:number):Promise<AxiosResponse<HttpStatusCode.Accepted>>{
        return $api.post('witch-tasks/getHistoryOfCompleatedTasks', {limit,page})
    }

    static async get_compleated_tasks_count():Promise<AxiosResponse<number>>{
        return $api.get('witch-tasks/get_compleated_tasks_count')
    }

    static async set_task_compleated(taskId:number, limit:number, page:number):Promise<AxiosResponse>{
        return $api.post('witch-tasks/set_task_compleated',{taskId, limit, page})
    }


}

