import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Image, OverlayTrigger, Popover} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {bottom} from "@popperjs/core";
import ListOfSearch from "./ListOfSearch";
import {metrika} from "../../utils/metrika";


const SearchBar= observer(() => {
const target = useRef(null);
const {navbar} = useContext(Context)
const [show, setShow] = useState(false)
const [value, setValue] = useState('')

useEffect(()=>{
    if (value.length<2){
        setShow(false)
    }else {
        setShow(true)
    }
},[value, show])

    return (
        <div className=" d-flex flex-row justify-content-end ml-4"
             ref={target}
             style={{width:150, marginTop:3
             }}>
            {navbar.getNavBar()?
                    <div>
                    <Form  onSubmit={e=>e.preventDefault()}>
                        <Form.Group>
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                key={bottom}
                                placement={bottom}
                                overlay={
                                    <Popover

                                        style={{width:250, offset: 400, }}
                                        id={`popover-positioned-${bottom}`}>
                                            <ListOfSearch searchText={value}/>
                                    </Popover>
                                }
                            >
                            <Form.Control
                                placeholder="Введите имя гадалки"
                                style={{width:250,
                                    height:36,
                                    borderRadius:5,
                                    borderColor:"lightgray",
                                    background:"white",
                                    zIndex:3,
                                    boxShadow:"none",
                                    marginRight:-40,
                                    marginTop:4,
                                }}
                                value={value}
                                onChange={e=> setValue(e.target.value)}
                               />
                            </OverlayTrigger>
                        </Form.Group>
                    </Form>
                    </div>
               :
                <div></div>
                    }

            <Button onClick={()=>{
                        navbar.setNavBar()
                        setValue('')
                        metrika('reachGoal','ClickToSearch')
                        }}
                    style={{ borderRadius:30,
                        marginRight:25,
                        background:"none",
                        border: "none",
                        boxShadow:"none",
                        zIndex:1,
                        height:45}}
            >
                {navbar.getNavBar()?
                    <Image style={{marginBottom:3}} alt="Гадание таро по видео" width={20} height={20} src={`${process.env.REACT_APP_STAT_URL}/close.png`}/>:
                    <Image style={{marginBottom:3}} alt="Гадание таро по видео" width={20} height={20} src={`${process.env.REACT_APP_STAT_URL}/search.png`}/>
                }
            </Button>
        </div>
    );
});

export default SearchBar;