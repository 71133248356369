import socketIOClient from "socket.io-client";
import React, {createContext, useContext, useEffect} from "react";
import {Context} from "../index";
import {toast} from "react-toastify";
import {iTaskForWitch} from "./ListOfSteamsStore";
import {v4} from "uuid"



const WS :string = `${process.env.REACT_APP_API_WS}`

export const WebSocketContext = createContext<null | any>(null)

let jwtTokenFieldName =  localStorage.getItem('token');
export const ws = socketIOClient(WS,
    {
        transports: ['websocket'],
        query: { jwtTokenFieldName } })


interface IWebSocketProvider {
    children:React.ReactNode
}

export const WebSocketProvider: React.FC<IWebSocketProvider>=({children})=>{


    const {user, privateChat} = useContext(Context)
    const {steam} = useContext(Context)



    useEffect(()=>{
        ws.on('connected', ()=>{
            setTimeout(()=>ws.emit('checkAreYouInRoom'),300)
        })

        ws.on('user-joined-room',(res)=>{


            console.log('В комнату '+res.witchId +
                ' подключился пользователь '
                + res.userId
                + ' clientId: '
                + res.clientId
                + ' Количество пользователей в комнате: '
                + res.viewers)
            const index = steam.getSteamArr().findIndex(ws=>ws.witchId===steam.getCurrentWitchId())
          //  console.log('index '+ index)
            if (index===-1)return
            const buffer = steam.getSteamArr()
            buffer[index].viewers = res.viewers
            steam.setSteamArr(buffer)

        })


        ws.on('witchTasksinitialLoading',(res
        )=>{

            const bufferArr:iTaskForWitch[] =[]
            Object.values(res.tasks).forEach((t:any)=>{
                bufferArr.push(
                    {
                        createdAt:t.createdAt.replace('T',' ').replace('Z','').split('.')[0],
                        authorId:t.authorId,
                        authorName:t.authorName,
                        authorPicId:t.authorPicId,
                        taskId:t.id,
                        isTaskCompleated:t.isTaskCompleated,
                        task:t.task,
                        taskType:t.taskType,
                        updatedAt:t.updatedAt.replace('T',' ').replace('Z','').split('.')[0],
                        linkToPrivateRoom:t.linkToPrivateRoom
                    })
                }
            )

            user.setTasksForWitch(bufferArr.slice(0).reverse())
         })


        ws.on('witchTasksNewTask',(res
        )=>{

            const bufferArr:iTaskForWitch[] =[]

            Object.values(res.tasks).forEach((t:any)=>{
                    bufferArr.push(
                        {
                            createdAt:t.createdAt.replace('T',' ').replace('Z','').split('.')[0],
                            authorId:t.authorId,
                            authorName:t.authorName,
                            authorPicId:t.authorPicId,
                            taskId:t.id,
                            isTaskCompleated:t.isTaskCompleated,
                            task:t.task,
                            taskType:t.taskType,
                            updatedAt:t.updatedAt.replace('T',' ').replace('Z','').split('.')[0],
                            linkToPrivateRoom:t.linkToPrivateRoom
                        })
                }
            )

            user.setTasksForWitch(bufferArr)
            toast.success('Получено новое задание на гадание от '+ user.getTasksForWitch()[0].authorName,{autoClose: 1000})
            const soundString = v4()
            user.setSound(soundString)
        })



        ws.on('user-left-the-room',(res)=>{
            console.log('Из комнаты '
                +res.witchId
                + ' отключился пользователь '
                + res.userId
                + ' clientId: '
                + res.clientId
                + ' Количество пользователей в комнате: '
                + res.viewers)

            if (!user.getIsInPrivateChat()){
                const index = steam.getSteamArr().findIndex(ws=>ws.witchId===steam._currentWitchId)
                const buffer = steam.getSteamArr()
                buffer[index].viewers = res.viewers
                steam.setSteamArr(buffer)
            }
        })

        ws.on('coinsOnAccount',(res)=>{
            user.setUser({...user.getUser(), userCoinCount:res.coins})
        })

        ws.on('RequestOfChatIndividual',
            res=>{
            if (!user.getIsInPrivateChat()){
                ws.emit('gettingWitchChatIndividual',{witchId:res.witchId})
                ws.emit('gettingCurrentGadanieUser',{witchId:res.witchId})
            }
        })


        ws.on('RequestOfChatIndividualPrivate',
            res=>{
                if (user.getIsInPrivateChat()){
                    ws.emit('gettingPrivateChatIndividual',{privateRoomLink:res.privateRoomLink})
                }
            })

        ws.on('authCheck',()=>{
           // console.log('authCheck')
        })


        ws.on('MessageCreated',()=>{
            steam.setMessageInInput('')
            steam.setInputResponseErrors('')
        })


        ws.on('MessageCreatedInPrivateRoom',()=>{
            steam.setMessageInInput('')
            steam.setInputResponseErrors('')
        })

        ws.on('SendingWitchChat',(resArr)=>{
            if (!user.getIsInPrivateChat()) {
                steam.setMessagesInChat(resArr.resArr)
            }
        })

        ws.on('SendingPrivateChat',(resArr)=>{
            if (user.getIsInPrivateChat()) {
                steam.setMessagesInChat(resArr.resArr)
            }
        })


        ws.on('exception',(e)=>{
            steam.setInputResponseErrors(e)
            if (String(e)!=="witchId - Должно быть числом"&&String(e)!=='недостаточно монет'){
                toast.warning(String(e),{autoClose: 1000})
            }


            if(String(e)==='недостаточно монет'){
                user.setNotEnoughtCoins(true)
            }

        })

        ws.on('message',(res)=>
        {
            if (res==='unauthorized'){

            user.checkRefreshToken()
            steam.setInputResponseErrors('Уупс.., вас долго не было на месте, повторите попытку')
            toast.warning(String('Уупс.., вас долго не было на месте, повторите попытку'),{autoClose: 1000})

        }}
        )

        ws.on('lineForGadaniye',
            res=>{
             //   console.log(res)
                user.setLineForGadanie({indexes:res.indexes})
        }
        )

        ws.on('currentGadaniyeUser',
            res=> {

                user.setCurrentGadaniyeUser({
                    userpicId: res.userpicId,
                    userName: res.userName
                })
                if (user.getIsAuth()){
                    ws.emit('requestLineForGadaniye',{witchId:steam.getCurrentWitchId()})
                }

            }
        )

        ws.on('currentGadaniyeUserInitial',
            res=> {
                user.setCurrentGadaniyeUser({
                    userpicId: res.userpicId,
                    userName: res.userName
                })
                if (user.getIsAuth()){
                    ws.emit('requestLineForGadaniye',{witchId:steam.getCurrentWitchId()})
                }
            }
        )



        ws.on('sendLinksToPrivateChat',
            res=> {
                user.setLinksToPrivatChat(res)
              //  console.log(res)
                if (res.length>0){
                    user.setIsPendingPrivatChat(true)
                }
            }
        )


        ws.on('currentGadaniyeUserAfterTaskCompleated',
            res=>{
                user.setCurrentGadaniyeUser({
                    userpicId: res.userpicId,
                    userName: res.userName
                })
                if (user.getIsAuth()){
                    ws.emit('requestLineForGadaniye',{witchId:steam.getCurrentWitchId()})
                }
            }
        )


        ws.on('pongWS',
            ()=> {
                    user.setPingWS(true)
                }

        )

        ws.on('witchChangaedMuttedStatus',
            res=> {
                const allWitches = steam.getSteamArr()
                const index = allWitches.findIndex(w=>w.witchId===res.witchId)
                allWitches[index].isMicMuted=res.muted
                steam.setSteamArr(allWitches)
            }
        )

        ws.on('onUpdatePrivateStatus',res=>{
           const allWitches = steam.getSteamArr()
           const index = allWitches.findIndex(w=>w.witchId===res.witchId)
           allWitches[index].isPrivate=res.isPrivate
           steam.setSteamArr(allWitches)
        })

        ws.on('inRoomCheck',
            res=> {
              //  console.log('youAreInRoom: '+res.youAreInRoom)

                if(!res.youAreInRoom){
                    ws.emit('onLeftRoom')

                    if (user.getIsInPrivateChat()){
                        if (user.getIsAuth()){
                            ws.emit('JoinPrivateRoom',{privateRoomLink:privateChat.getRoom(), userOrWitchId:user.getUser().userId})
                        }
                    }

                    if (user.getIsAuth()){
                        if (typeof steam.getCurrentWitchId()==='number'){
                            ws.emit('JoinRoom',{witchId:steam.getCurrentWitchId(), userId:user.getUser().userId})
                        }else {
                            ws.emit('JoinRoom',{witchId:user.getUser().userId, userId:user.getUser().userId})
                        }

                    }else {
                        if (typeof steam.getCurrentWitchId()==='number'){
                            ws.emit('JoinRoom',{witchId:steam.getCurrentWitchId(), userId:91})
                        }else {
                            ws.emit('JoinRoom',{witchId:91, userId:91})
                        }
                    }
                }

            }

        )


    },[])




    const createMessage=(
                         message:string,
                         type:string,
                         authorPicId:number,
                         authorName: string)=>{


            let messSendingComand: string = ''
            switch (type) {
                case 'general':
                    messSendingComand = 'sendMessage'
                    break
                case 'paidButNotPrivate':
                   // console.log('paidButNotPrivate')
                    messSendingComand = 'sendMessagePaidButNotPrivate'
                    break
                case 'private':
                    messSendingComand = 'sendMessagePrivate'
                    break
                default:
                    messSendingComand = 'sendMessage'
                    break
            }
        if(!user.getIsInPrivateChat()) {
            ws.emit(messSendingComand,
                {
                    witchId: steam.getCurrentWitchId(),
                    message: message,
                    type: type,
                    authorPicId: authorPicId,
                    authorName: authorName,
                })
        }else {
           // console.log(privateChat.getRoom())
            ws.emit('privateChatSendMessage',
                {
                    linkToPrivateRoom:privateChat.getRoom(),
                    message: message,
                })
        }
    }


   /* const witchTaskCompleated=(taskId:number)=>{

        ws.emit('witchTaskCompleated',
            {
                taskId:taskId
            })
    }*/

    const onLeftRoom=()=>{
        ws.emit('onLeftRoom')
    }

    const sendTextToChat = (CoinCount:number) =>{
        ws.emit('sendCoins',{
            witchId: steam.getCurrentWitchId(),
            transaction: CoinCount
        })
    }

    const sendRequestToUserForProvatnoeGadanie = (authorId:number)=>{
        ws.emit('sendRequestToUserForProvatnoeGadanie',{
            authorId: authorId
        })
    }

    const witchMuttedStatus = (muted:boolean)=>{
        ws.emit('witchMuttedStatus',{
            muted: muted
        })
    }

    const onJoinroom=()=>{


                ws.emit('onLeftRoom')
            if (user.getIsAuth()){
                if (typeof steam.getCurrentWitchId()==='number'){
                    ws.emit('JoinRoom',{witchId:steam.getCurrentWitchId(), userId:user.getUser().userId})
                }else {
                    ws.emit('JoinRoom',{witchId:user.getUser().userId, userId:user.getUser().userId})
                }

            }else {
                if (typeof steam.getCurrentWitchId()==='number') {
                    ws.emit('JoinRoom', {witchId: steam.getCurrentWitchId(), userId: 91})
                }else {
                    ws.emit('JoinRoom', {witchId: 91, userId: 91})
                }
            }
    }


    const onJoinPrivateRoom=(privateRoomLink:number, userOrWitchId:number)=>{
        ws.emit('onLeftRoom')
        if (user.getIsAuth()){
                ws.emit('JoinPrivateRoom',{privateRoomLink:privateRoomLink, userOrWitchId:userOrWitchId})
            }
        }




    const onRoleCheck=()=>{
            ws.emit('RoleCheck', {witchId:user.getUser().userId})
        }

    const pingWs=async ()=>{
        user.setPingWS(false)
        ws.emit('pingWS')
        setTimeout(()=>{
            if(user.getPingWS()===true){
                pingWs()
            }
        }, 4000)
    }

    return(
        <WebSocketContext.Provider value={{
            ws, createMessage,
           // witchTaskCompleated,
            onLeftRoom, onJoinroom,
            sendTextToChat,onRoleCheck, onJoinPrivateRoom, pingWs,sendRequestToUserForProvatnoeGadanie,witchMuttedStatus
        }}>{children}</WebSocketContext.Provider>
    )
}
