import React, {FC, useContext} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import SearchBar from "../SearchBar/SearchBar";
import {MAIN_ROUTE, PRICE_ROUTE} from "../../utils/consts";
import {useNavigate} from "react-router-dom";
import Entrance from "../Entrance/Entrance";
import Registration from "../Registration/Registration";
import AccountCorner from "../AccountCorner/AccountCorner";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {Button, Spinner} from "react-bootstrap";

interface INavBar{}




const NavBar:FC<INavBar> = observer(() => {

    const navigate = useNavigate()
    const {user} = useContext(Context)

    if (user._isLoading){
        return <Spinner animation="border"/>
    }


    return (
        <Navbar className="d-flex flex-row flex-wrap justify-content-between"
                style={{borderBottomStyle:"solid",
                        borderBottomColor:"lightgray",
                        borderBottomWidth:'1px',

                        }}>

                    <span  className="d-flex align-self-stretch text-nowrap"
                           onClick={()=>{
                               navigate(MAIN_ROUTE+ 'random')

                           }}
                           style={{fontSize:24,
                                  fontFamily:"Montserrat",
                                  fontWeight:"bold",
                                  marginRight:25,
                                  verticalAlign:"top",
                                  marginBottom:5,
                                  cursor:"pointer",
                                  }}>

                            Погадай таро

                    </span>


                    <div className="d-flex flex-row justify-content-end flex-wrap-reverse align-items-end">
                                <div className="d-flex flex-row justify-content-end" style={{width:290}}>
                                    <SearchBar/>
                                </div>
                            {user._isAuth?
                                <div className="d-flex flex-row" style={{width:70}}>
                                    <AccountCorner/>

                                </div>:
                                    <div className="d-flex flex-row flex-wrap" style={{marginTop:6}}>
                                        <Entrance/>
                                        <Registration/>
                                        <Button
                                            variant="outline-light"
                                            style={{color:"gray"}}
                                            onClick={()=>navigate(PRICE_ROUTE)}
                                        >
                                            Стоимость
                                        </Button>
                                    </div>
                              }
                    </div>

        </Navbar>

    );
});

export default NavBar;

