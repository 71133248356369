import React, {useContext, useEffect} from 'react';
import { Spinner} from "react-bootstrap";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import ForPrice from "../components/ForPrice/ForPrice";


const Price = observer(() => {
    const {price} = useContext(Context)

    useEffect(() => {
        price.udpatePrice()
    },[])


    if (!price.getIsPriceLoaded()) {
        return (<Spinner/>)
    } else {

        const priceLine = price.getPrice()
        interface IPriceWithAdditionalRows{
            id: number,
            description: string,
            coins_count: number,
            price: string,
            price_before_discount:number,
            discount_in_percent:number,
        }
        let priceLineWithAdditionalRows ={} as IPriceWithAdditionalRows[]

        priceLine.forEach((p, index)=>{
            const price_before_discount = priceLine[0].coins_count===0?0:
                (Number(priceLine[0].price)/priceLine[0].coins_count)*p.coins_count

            const discount_in_percent = price_before_discount===0?0:Math.ceil((price_before_discount - Number(p.price))/price_before_discount*100)

            priceLineWithAdditionalRows[index]={
                id:p.id,
                description:p.description,
                coins_count:p.coins_count,
                price:p.price,
                price_before_discount:price_before_discount,
                discount_in_percent:discount_in_percent
            }
        })


        return (
            <div className="d-flex flex-column flex-nowrap justify-content-start" style={{ marginTop:4}}>

                <h2 className="ml-3">Цены на гадание</h2>
                <div className="ml-3" style={{maxWidth:700}}>
                    Оплата гадания производится в виртуальной валюте "Монеты",
                    которы можно приобрести после регистрации удобным для вас способом.<br/><br/>
                    <h4>Стоимость гадания "Погадай на свой вопрос": 30 монет</h4>
                    При гадании "Погадай на свой вопрос" ваш вопрос видит только гадалка, но расклад и советы могут все.
                    <br/>
                    <br/>
                    <h4>Стоимость гадания "Индивидуальное гадание": 80 монет</h4>
                    В режиме "Индивидуальное гадание" ваш вопрос, расклад, и видео можете видеть только вы.
                    <br/>
                    <br/>
                    <h4>Стоимость монет:</h4>

                </div>

                <div className="d-flex flex-row flex-wrap justify-content-start" style={{ marginTop:4}}>
                        <ForPrice
                            count_of_coins={priceLineWithAdditionalRows[0].coins_count}
                            price={Number(priceLineWithAdditionalRows[0].price)}
                            price_before_discount={priceLineWithAdditionalRows[0].price_before_discount}
                            discount_in_percent={priceLineWithAdditionalRows[0].discount_in_percent}
                            description={priceLineWithAdditionalRows[0].description}/>
                        <ForPrice
                            count_of_coins={priceLineWithAdditionalRows[1].coins_count}
                            price={Number(priceLineWithAdditionalRows[1].price)}
                            price_before_discount={priceLineWithAdditionalRows[1].price_before_discount}
                            discount_in_percent={priceLineWithAdditionalRows[1].discount_in_percent}
                            description={priceLineWithAdditionalRows[1].description}/>
                        <ForPrice
                            count_of_coins={priceLineWithAdditionalRows[2].coins_count}
                            price={Number(priceLineWithAdditionalRows[2].price)}
                            price_before_discount={priceLineWithAdditionalRows[2].price_before_discount}
                            discount_in_percent={priceLineWithAdditionalRows[2].discount_in_percent}
                            description={priceLineWithAdditionalRows[2].description}/>
                        <ForPrice
                            count_of_coins={priceLineWithAdditionalRows[3].coins_count}
                            price={Number(priceLineWithAdditionalRows[3].price)}
                            price_before_discount={priceLineWithAdditionalRows[3].price_before_discount}
                            discount_in_percent={priceLineWithAdditionalRows[3].discount_in_percent}
                            description={priceLineWithAdditionalRows[3].description}/>
                </div>
            </div>
        )



    }
})


export default Price;