import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import axios from 'axios'
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";


const WheelOfFort = () => {

const [stream, SetSteam] = useState<MediaStream>()


    const init =async ()=> {
        const stream = await navigator.mediaDevices.getUserMedia({ video: {width:640, height:480} });
        SetSteam(stream);
        const peer = createPeer()
        stream.getTracks().forEach(track => peer.addTrack(track, stream));
        }


    const createPeer=()=> {
        const peer = new RTCPeerConnection({
            iceServers: [
                {
                    urls: "stun:stun.stunprotocol.org"
                }
            ]
        });
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(peer);
        return peer;
    }

    const handleNegotiationNeededEvent=async (peer:any)=> {
        const offer = await peer.createOffer();
        await peer.setLocalDescription(offer);
        const payload = {
            sdp: peer.localDescription
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/broadcast`, payload);
        const desc = new RTCSessionDescription(data.sdp);
        peer.setRemoteDescription(desc).catch((e:any) =>{
           // console.log(e)
    }
        );
    }

    const GetStatusOfStreaming=()=>{
      //  console.log(stream?.active)
    }

    const DeleteStreaming=()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/deletstreaming`)
    }

    return (
        <div>
            <Button onClick={()=>init()} className="m-5">Init</Button>
            {(stream!)?<VideoPlayer stream={stream}/>: <div></div> }
            <Button onClick={()=>GetStatusOfStreaming()} className="m-5">Get Status</Button>
            <Button onClick={()=>DeleteStreaming()} className="m-5">Stop streaming</Button>
        </div>
    );
};

export default WheelOfFort;