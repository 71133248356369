import $api from "../http/forTraining";
import {AxiosResponse} from "axios";

import {ISteamers} from "../store/ListOfSteamsStore";

export default class StreamsService{
    static async getWitch():Promise<AxiosResponse<ISteamers[]>>{
        return $api.get('/witch')
    }

}
