import {makeAutoObservable} from "mobx";
export     interface IAvatarSelect {
    avatar_id:number;
    avatar_is_selected:boolean;
    image_source:string;
}


export default class AvatarSelectedStore{

_avatarSelected: IAvatarSelect[]

    constructor() {

        this._avatarSelected=  [
            {avatar_id:0,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/zero.png`},
            {avatar_id:1,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/first.png`},
            {avatar_id:2,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/second.jpeg`},
            {avatar_id:3,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/three.png`},
            {avatar_id:4,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/four.jpeg`},
            {avatar_id:5,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/five.jpeg`},
            {avatar_id:6,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/six.png`},
            {avatar_id:7,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/seven.jpeg`},
            {avatar_id:8,avatar_is_selected:false, image_source:`${process.env.REACT_APP_STAT_URL}/avatars/eight.png`},
        ]
        makeAutoObservable(this)
    }

    setAvatarSelected(avatar:IAvatarSelect[]){
        this._avatarSelected= avatar
    }

    getAvatarSelected(){
        return this._avatarSelected
    }


}
