import React, {FC} from 'react';
import {iTaskForWitch} from "../../store/ListOfSteamsStore";



const CompleatedTaskForWitch:FC<iTaskForWitch> = (props) => {


    return (

        <div className="d-flex flex-row justify-content-between">

            <div className="mt-1" style={{width:230,  textAlign:'center'}}>{props.updatedAt}</div>
            <div className="mt-1" style={{width:150,textAlign:'center'}}>{props.taskId}</div>
            <div className="mt-1" style={{width:170, textAlign:'center'}}>{props.authorName}</div>
            <div className="mt-1" style={{width:170, textAlign:'center'}}>{props.task}</div>

        </div>

    );
};

export default CompleatedTaskForWitch;