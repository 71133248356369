import React, {createContext} from "react";
import {IonSFUJSONRPCSignal} from "ion-sdk-js/lib/signal/json-rpc-impl";
import {Client, LocalStream} from "ion-sdk-js";
import {useParams} from "react-router-dom";

export const IONContext = createContext<null | any>(null)

interface IIONStore {
    children:React.ReactNode
}

export const IONProvider: React.FC<IIONStore>=({children})=>{
    const {witchNick} = useParams()
    class Peer {
        private client: any;
        private readonly signal: IonSFUJSONRPCSignal;
        public media!: LocalStream;
        public audio:boolean
        private streams:any

        constructor() {
            this.audio = false;
            this.signal = new IonSFUJSONRPCSignal(
                `${process.env.REACT_APP_API_ION}`
            )
            this.streams= {};
            this.client = new Client(this.signal);
            this.signal.onopen = () => this.client.join("test room");
            this.client.ontrack = (track:MediaStreamTrack, stream:LocalStream) => {
              //  console.log("got track: ", track.id, "for stream: ", stream.id)
                if (track.kind === "video") {
                    if (!this.streams[stream.id]) {
                        stream.onremovetrack = () => {
                            this.streams[stream.id] = null;
                        };
                        this.streams[stream.id] =  stream;
                    }
                }
            }


        }

        async capture(audio:boolean) {
            await LocalStream.getUserMedia({
                resolution: "hd",
                audio: audio,
                codec: "vp8",
                video:{
                    width:640,
                    height:480
                }
            })
                .then((media) => {
                    this.media = media;
                })
                .catch(console.error);
        }

        publish() {
            this.client.publish(this.media);
        }

        unpublish() {
            this.media.unpublish();
            this.streams = null
        }
    }


    /*useEffect(()=>{
        if(peers.length===0){join()}
    },[])

    const join= async () => {
        const peer = await new Peer();
        await peer.capture(false);
        peers.push(peer);
    }*/

    return(
        <IONContext.Provider value={{  witchNick, Peer}}>{children}</IONContext.Provider>
    )
}