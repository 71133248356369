import React, {useContext, useEffect} from 'react';
import CoinBay from "../CashIn/CoinBay/CoinBay";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {Spinner} from "react-bootstrap";

const AccountCashIn = observer(() => {
    const {price} = useContext(Context)

    useEffect(()=>{
        price.udpatePrice()
    },[])


    if (!price.getIsPriceLoaded()){
        return (<Spinner/>)
    }else {
        const priceLine = price.getPrice()
        interface IPriceWithAdditionalRows{
            id: number,
            description: string,
            coins_count: number,
            price: string,
            price_before_discount:number,
            discount_in_percent:number,
        }
        let priceLineWithAdditionalRows ={} as IPriceWithAdditionalRows[]

        priceLine.forEach((p, index)=>{
            const price_before_discount = priceLine[0].coins_count===0?0:
                (Number(priceLine[0].price)/priceLine[0].coins_count)*p.coins_count

            const discount_in_percent = price_before_discount===0?0:Math.ceil((price_before_discount - Number(p.price))/price_before_discount*100)

            priceLineWithAdditionalRows[index]={
                id:p.id,
                description:p.description,
                coins_count:p.coins_count,
                price:p.price,
                price_before_discount:price_before_discount,
                discount_in_percent:discount_in_percent
            }
        })

    return (
        <div className="d-flex flex-column flex-wrap align-items-center">
            <CoinBay
                count_of_coins={priceLineWithAdditionalRows[1].coins_count}
                price={Number(priceLineWithAdditionalRows[1].price)}
                price_before_discount={priceLineWithAdditionalRows[1].price_before_discount}
                discount_in_percent={priceLineWithAdditionalRows[1].discount_in_percent}
                description={priceLineWithAdditionalRows[1].description}
            />
            <CoinBay
                count_of_coins={priceLineWithAdditionalRows[3].coins_count}
                price={Number(priceLineWithAdditionalRows[3].price)}
                price_before_discount={priceLineWithAdditionalRows[3].price_before_discount}
                discount_in_percent={priceLineWithAdditionalRows[3].discount_in_percent}
                description={priceLineWithAdditionalRows[3].description}
            />

        </div>
    );
}});

export default AccountCashIn;