import React, {FC, useContext, useEffect} from 'react';
import {Image} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import Scroll from 'react-scroll';
import {TypeOfMessageInChat} from "../../store/ListOfSteamsStore";

const Element = Scroll.Element
const scroller = Scroll.scroller


const srcollFuncDown = ()=>{
    scroller.scrollTo('myScrollToElementDown', {
    duration: 1500,
    delay: 1000,
    smooth: true,
    containerId: 'ContainerElementID',
    offset: 50})
}

const srcollFuncUp = ()=>{
    scroller.scrollTo('myScrollToElementUp', {
        duration: 1500,
        delay: 1000,
        smooth: true,
        containerId: 'ContainerElementID',
        offset: 0})
}

const MessageInChat:FC<{reversed:boolean}>= observer( ({reversed}:{reversed:boolean}) => {

    const {steam, user} = useContext(Context)

    useEffect(()=>{

        if(!reversed) {srcollFuncDown()} else
        {srcollFuncUp()}
    }, [steam._messagesInChat])

    let Arr
    if(!reversed){
        Arr = steam._messagesInChat.slice(0).reverse() }
    else {
        Arr = steam._messagesInChat
    }

    return (

        <div id='ContainerElementID'
            className="d-flex flex-column-reverse"
             style={{overflowY:"auto", width:'100%', borderStyle:"none"}}>

            <div className="d-flex flex-column justify-content-end"
                 style={{width:'100%', borderStyle:"none" }}>
                <Element name="myScrollToElementUp"></Element>
                {
                    Arr.map(mess=>
                            <div key={mess.messageId}
                                 className="d-flex flex-row"
                                 style={{
                                     width:'100%',
                                     marginTop:5}}>

                                <Image  className="m-2"
                                        src={steam.getUserPic(mess.userpic)}
                                        alt="Гадание таро по видео"
                                        style={{
                                            width:25,
                                            height:25}}/>
                                {
                                    mess.messageType == TypeOfMessageInChat.coinsGift?
                                            <div className="text-wrap"
                                                 style={{
                                                     borderColor:"lightskyblue",
                                                     borderStyle:"solid" ,
                                                     borderRadius:8,
                                                     paddingLeft:10,
                                                     paddingRight:10,
                                                     marginBottom:3,
                                                     fontFamily:"Montserrat",
                                                     fontSize:15,
                                                     marginTop:8
                                                 }}>
                                                <Image className="moneta "
                                                       style={{
                                                           marginLeft:3,
                                                           marginRight:3,
                                                           marginBottom:3}}
                                                       alt="Гадание таро по видео"
                                                       width={20}
                                                       height={20}
                                                       src={`${process.env.REACT_APP_STAT_URL}/coin.webp`}/>
                                                {mess.text}


                                            </div>:
                                    mess.messageType == TypeOfMessageInChat.paidButNotPrivate?
                                            <div className="text-wrap"
                                                 style={{
                                                     background:"lightblue",
                                                     borderRadius:8,
                                                     paddingLeft:10,
                                                     paddingRight:10,
                                                     marginBottom:3,
                                                     fontFamily:"Montserrat",
                                                     fontSize:15,marginTop:10,
                                            }}>
                                                {mess.text}
                                            </div>:
                                    mess.messageType == TypeOfMessageInChat.private?
                                            <div className="text-wrap"
                                                 style={{
                                                     width: 250,
                                                     background:"lightcoral",
                                                     borderRadius:8,
                                                     paddingLeft:10,
                                                     marginBottom:3,
                                                     fontFamily:"Montserrat",
                                                     fontSize:15,
                                                     marginTop:8,
                                                 }}>
                                                Запрос на индивидуальное гадание от "{mess.user}"
                                            </div>:
                                            <div className="text-wrap text-break"
                                                 style={{
                                                     marginTop:8,
                                                     width: 250,
                                                     borderRadius:8,
                                                     paddingLeft:10,
                                                     fontFamily:"Montserrat",
                                                     fontSize:15
                                                 }}>
                                                {mess.user} : {mess.text}
                                            </div>
                                }

                            </div>
                )}
                <Element name="myScrollToElementDown"></Element>
            </div>

        </div>

    );
});

export default MessageInChat;

