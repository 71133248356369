import React, {FC, useContext, useRef, useState} from 'react';
import {Button, Image} from "react-bootstrap";
import {Context} from "../../index";
import {IonSFUJSONRPCSignal} from "ion-sdk-js/lib/signal/json-rpc-impl";
import {Client, LocalStream} from "ion-sdk-js";
import './WitchStreamVideo.css'
import {WebSocketContext} from "../../store/WsContext";


interface IWitchStreamVideo{
    isPrivate:boolean,
    room:string|null
}

const config = {
    iceServers: [
        {
            urls: "stun:stun.l.google.com:19302",
        },
    ],
};

const WitchStreamVideo:FC<IWitchStreamVideo> = ({isPrivate,room}:IWitchStreamVideo) => {
    const pubVideo = useRef<HTMLVideoElement>(null)
    const [onLine, SetOnLine] = useState<boolean>(false)
    const [muted, SetMuted] = useState<boolean>(false)
    const [buttenActive, SetButtenActive] = useState<boolean>(false)
    const {user,steam} = useContext(Context)
    const [peers, SetPeers] = useState<Peer[]>()
    const {witchMuttedStatus} = useContext(WebSocketContext)


  /*  useEffect(()=>{
      //  console.log('medisStr', mediaStr)
       // console.log('peers updated:', peers)
        //mediaStr?.getTracks().forEach(track=>track.stop())
    },[mediaStr, peers])*/

    class Peer {
        public client: any;
        public signal ={} as IonSFUJSONRPCSignal;
        public media!: LocalStream;
        public audio:boolean
        public streams:any

        constructor(audio:boolean, room:string) {
            this.audio = false;
            this.signal = new IonSFUJSONRPCSignal(
                `${process.env.REACT_APP_API_ION}`
            )
            this.streams= {};
            this.client = new Client(this.signal);
            this.signal.onopen = () => this.client.join(room).then(
                ()=>{
                    console.log('this.capture(audio): '+audio)
                    this.capture(audio)
                }
            );
            this.signal.onclose = () => {SetButtenActive(false)
                console.log('signal close')
            }

                this.client.ontrack = (track:MediaStreamTrack, stream:LocalStream) => {
                    console.log("got track: ", track.id, "track type: ", track.kind,"for stream: ", stream.id)
                    if (track.kind === "video") {
                        if (!this.streams[stream.id]) {
                            stream.onremovetrack = () => {
                                this.streams[stream.id] = null;
                              //  console.log('remove track '+ stream.id)
                            };
                            this.streams[stream.id] =  stream;
                        }
                    }
                }

        }

        async capture(audio:boolean) {
          //  console.log('in capture')
            await LocalStream.getUserMedia({
                resolution: "qhd",
                audio: audio,
                codec: "vp8",
                video:{
                    width:1920,
                    height:1080
                }
            })
                .then((media) => {
                    this.media = media;
                    this.client.publish(media)
                  //  console.log('before if (pubVideo.current ')
                    if (pubVideo.current){
                        pubVideo.current.srcObject = media;
                        pubVideo.current.autoplay = true;
                        pubVideo.current.muted = true;
                        pubVideo.current.play()
                      //  console.log('in if (pubVideo.current ')
                    }
                    SetOnLine(true)

                })
                .catch(console.error);
        }

        publish() {
            this.client.publish(this.media);
        }

        async unpublish() {
            await this.media.unpublish()
            // await this.media.getTracks().forEach(async track=>await track.stop())

        }
        async closeWs(){
            SetButtenActive(true)
            this.media.unpublish()
            this.media.getTracks().forEach(track=>track.stop())
            this.client.leave()
            this.signal.close()

            SetPeers([])
            buffer_peers = []
        }
    }

    let buffer_peers:Peer[] = []



    const start= async () => {
        const peer = new Peer(!muted, "room"+user.getUser().userId);
        // await peer.capture(false)
        buffer_peers[0]=(peer)
        SetPeers(buffer_peers)
        steam.setOnLineWitchStatus(true)
    }



    const stop= async ()=>{
        SetButtenActive(false)
        if(typeof peers!=="undefined")peers[0].closeWs()
        if (pubVideo.current){
            pubVideo.current.srcObject = null;
            pubVideo.current.autoplay = true;
            pubVideo.current.muted = true;
        }

        SetOnLine(false)
        steam.setOnLineWitchStatus(false)

    }

    const changeMuted = () =>{
        SetMuted(!muted)
        if(typeof peers!=="undefined")
            {
                peers[0].unpublish()
                peers[0].media.getTracks().forEach(track=>track.stop())
                peers[0].capture(muted)
                peers[0].publish()
            }
        witchMuttedStatus(!muted)
    }


    return (
        <div style={{width:'100%'}} className="d-flex flex-column">
            <div>{user.getUser().userName}</div>
            <video
                id="pubVideo"
                muted
                autoPlay
                className="pubVideo bg-black"
                ref={pubVideo}
                style={{maxWidth:450}}
            >
            </video>
            <div className="d-flex mt-2 flex-row flex-wrap justify-content-between">
                {!onLine?
                    <Button variant="danger" disabled={buttenActive} onClick={()=>start()}>
                        Начать трансляцию
                    </Button>:
                    <div>
                        <Button variant="outline-danger" onClick={()=>stop()}>
                            Остановить трансляцию
                        </Button>

                    </div>
                }
                {!onLine? null:
                    <Button variant="danger" onClick={changeMuted}>
                        <Image
                            style={{width:25,height:25
                            }}
                            alt="Гадание таро по видео"
                            src={muted ?
                                `${process.env.REACT_APP_STAT_URL}/micoff.png`:
                                `${process.env.REACT_APP_STAT_URL}/micon.png`}/>
                    </Button>
                }

            </div>
        </div>
    );
};



export default WitchStreamVideo;