import {makeAutoObservable} from "mobx";




export default class NavBarStore{

    _barShowed: boolean

    constructor() {

        this._barShowed = false
        makeAutoObservable(this)
    }

    setNavBar(){
        this._barShowed = !this.getNavBar()
    }

    getNavBar(){
        return this._barShowed
    }
}
