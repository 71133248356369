import React, {useContext, useState} from 'react';
import {Button,  Form, Nav} from "react-bootstrap";
import {LOGIN_ROUTE} from "../../utils/consts";
import {useNavigate} from "react-router-dom";
import {Context} from "../../index";
import {WebSocketContext} from "../../store/WsContext";

const FormOfEnter = () => {
    const navigate = useNavigate()
    const [email, SetEmail] = useState('')
    const [password, SetPassword] = useState('')
    const [error, setError] = useState([])
    const {user} = useContext(Context)
    let {ws, onLeftRoom, onJoinroom } = useContext(WebSocketContext)

    const singIn = async ()=>{
        setError(await user.login(email,password))
        ws.io.opts.query ={
            jwtTokenFieldName:localStorage.getItem('token')
        }
        ws.disconnect().connect()
        onLeftRoom()
        onJoinroom()

    }

    return (
        <div
            className="d-flex flex-column justify-content-between align-items-start ml-3 mb-2"
            style={{background:"none", width:320}}>

            <Form
                className="d-flex flex-column"
                onSubmit={e=>{
                    e.preventDefault()
                    navigate(LOGIN_ROUTE)}}>
                <Form.Group>
                <Form.Control
                    className="mt-3"
                    placeholder="Введите имя...."
                    value={email}
                    onChange={e=>SetEmail(e.target.value)}
                />
                <Form.Control
                    className="mt-3"
                    placeholder="Введите ваш пароль...."
                    value={password}
                    onChange={e=>SetPassword(e.target.value)}
                    type="password"/>
                </Form.Group>
            </Form>
                <div className="d-flex mt-3 d-flex justify-content-between">
                    <div className="d-flex flex-column align-items-start">
                        <div>Нет аккаунта?</div>
                        <Nav.Link style={{padding:0}}
                                  onClick={()=>{
                                      user.setRegistrationModalShow(true);
                                      document.body.click()}}>
                            Зарегистрируйтесь!
                        </Nav.Link>
                    </div>
                    <div  className="justify-content-end ml-2">
                        <Button
                            variant={"outline-secondary"}
                            onClick={()=>singIn()}
                        >Войти</Button>

                    </div>
                </div>
            <div style={{maxWidth:220, marginTop:10}}>
            {typeof error==='string'?<div className="text-wrap" style={{wordWrap: 'break-word'}}>{error}</div>
                :error.map(m=><div style={{wordWrap: 'break-word'}} key={m}>{m}</div>)}
            </div>

        </div>
    );
};

export default FormOfEnter;