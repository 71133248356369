import React, {FC, useContext} from 'react';
import {Button, Form, Image, OverlayTrigger, Popover} from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {WebSocketContext} from "../../../store/WsContext";
import {metrika} from "../../../utils/metrika";


const MobileMainChatInput:FC = observer(() => {

    const {steam} = useContext(Context)
    const {user} = useContext(Context)
    const {createMessage} = useContext(WebSocketContext)

    const onEmojiClick = (emojiObject:string)=>{
        const prev = steam.getMessageInInput()
        steam.setMessageInInput(prev+emojiObject)
    }


    const sendTextToChat = async () =>{
        metrika('reachGoal','SendMessage')
        if(user.getIsAuth()){
            createMessage(steam.getMessageInInput(),'general', user.getUser().userpicId,user.getUser().userName)
        }else
        {
            user.setRegistrationModalShow(true)
            steam.setMessageInInput('')
        }

    }


    return (
            <div className='d-flex flex-column' style={{width: '100%',}}>
            <div className="d-flex flex-row justify-content-between align-content-start" style={{width: '100%',}}  >
                <Form style={{width: '70%'}}
                      onSubmit={e=>{
                          e.preventDefault()
                          sendTextToChat()
                      }}>
                    <Form.Group>
                        <Form.Control type="text"
                                      placeholder="Нажмите, чтобы начать общаться.."
                                      style={{boxShadow:"none", fontSize:11, height:38}}
                                      onChange={(e)=>steam.setMessageInInput(e.target.value)}
                                      value={steam.getMessageInInput()}
                                      //disabled={!user.getIsAuth()}
                        />
                    </Form.Group>
                </Form>

                <Button variant="outline-secondary  "
                        type="submit"
                        style={{width: '35%'}}
                        onClick={()=>sendTextToChat()}
                >
                    Отправить
                </Button>


            </div>
            </div>
    );
});

export default MobileMainChatInput;

