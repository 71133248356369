import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../index";
import {Spinner} from "react-bootstrap";
import Witch from "./witch/witch";
import {observer} from "mobx-react-lite";
import {MAIN_ROUTE} from "../utils/consts";
import {WebSocketContext} from "../store/WsContext";
import {useNavigate} from "react-router-dom";


const Studio = observer( () => {
    const {user,steam} = useContext(Context)
    const {onRoleCheck,onLeftRoom,onJoinroom} =useContext(WebSocketContext)
    const [loaded,SetLoaded] = useState(false)
    const [isWitch, SetIsWitch] = useState(false)
    const navigate = useNavigate()

    useEffect(()=>{
            onLeftRoom()
            user.setIsInPrivateChat(false)
            steam.setWitchPrivateStatus(false)

        },[])

    useEffect(()=>{
           // console.log('user._waitingRequestWitch '+user._waitingRequestWitch)
          //  console.log('user.getUser().userId '+user.getUser().userId)

            if(user._waitingRequestWitch===false && typeof user.getUser().userId ==='number'){

                    steam.setCurrentWitchId(user.getUser().userId)
                    onJoinroom()
                    onRoleCheck()
                    SetLoaded(true)
                    const witch = steam.getSteamArr().filter(e=>e.witchId===steam.getCurrentWitchId())[0]
                    if (witch) SetIsWitch(true)
                }
            }
        ,[steam._steamArr,user._user.userId])

    useEffect(() => {
       // console.log('witch: '+isWitch)
        if(!isWitch&&loaded){navigate(MAIN_ROUTE)}
    },[loaded])

    if (loaded){
        return (
            <div className="d-flex flex-column">
                <Witch/>
            </div>
        );}else {
        return <Spinner></Spinner>
    }
});

export default Studio;