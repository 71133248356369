import React, {useContext, useEffect, useState} from 'react';
import {Col, Pagination, Row, Spinner} from "react-bootstrap";
import MainChat from "../../components/MainChat/MainChat";
import MainChatReverse from "../../components/MainChat/MainChatReverse";
import TaskForWitch from "../../components/TaskForWitch/TaskForWitch";
import Viewers from "../../components/Viewers/Viewers";
import WitchStreamVideo from "../../components/WitchStreamVideo/WitchStreamVideo";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import CompleatedTaskForWitch from "../../components/TaskForWitch/CompleatedTaskForWitch";


const Witch =observer(() => {

    const {steam, user} = useContext(Context)
    const [width, setWidth] = useState(window.innerWidth)
    const updateWidth = () => {setWidth(window.innerWidth)}

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    },[])


    useEffect(()=>{
            user.setIsInPrivateChat(false)
        },[])


    const witch = steam.getSteamArr().filter(e=>e.witchId===steam.getCurrentWitchId())[0]


    useEffect(()=>{
        user.requestCompleatedTasks()
    },[user._pageWitchTasks])


    const pageCompleatedTasksCount = Math.ceil(user._countOfCompleatedTasksForWitch / 6)
    const pages:number[] = []
    for (let i = 0; i < pageCompleatedTasksCount; i++) {
        pages.push(i+1)
    }


    if(!witch){return <Spinner></Spinner>}
    else {
    return (

    <Row className="d-flex flex-wrap justify-content-between flex-row"
         style={{borderBottomStyle: "solid", borderBottomColor:"lightgray"}}>

        <Col lg={7} style={{borderRightStyle: "solid", borderRightColor:"lightgray"}}>


            <div className='d-flex flex-column justify-content-between' style={{ borderRadius:5, marginRight:15, marginLeft:15}}>
                <span style={{fontSize:20}}>Кабинет {witch.witchName}</span>

                <WitchStreamVideo isPrivate={false} room={null}/>
                <Viewers fontSize={20} viewersCount={witch.viewers}/>
            </div>

            <div style={{fontSize:20, marginLeft:15, marginTop:15}}>На счету {user.getUser().userCoinCount} монет</div>


            <div className="flex-row d-flex" style={{overflowX:"auto", margin:15}}>
               <div className="flex-row d-flex" >
                   {user.getTasksForWitch().slice(0).reverse().map(t=>{
                      return <TaskForWitch
                           key={t.taskId}
                           createdAt={t.createdAt}
                           authorId={t.authorId}
                           authorName={t.authorName}
                           authorPicId={t.authorPicId}
                           taskId={t.taskId}
                           isTaskCompleated={t.isTaskCompleated}
                           task={t.task}
                           taskType={t.taskType}
                           updatedAt={t.updatedAt}
                           linkToPrivateRoom={t.linkToPrivateRoom}
                      />

                   })}

               </div>
            </div>

        </Col>
        <Col lg={5} className="d-flex justify-content-start">

            {(width>991)?
                <MainChat/>
                :
                <MainChatReverse/>
            }

        </Col>



        <div style={{ display:"flex", flexDirection:"column", flexWrap:"nowrap", overflowX: "auto"}}>

         <div>
        <div className="d-flex flex-row mb-2 mt-4" style={{fontSize:16, fontWeight:'bold', fontFamily:"Montserrat"}}>
            <div className="mt-1" style={{width:230,  textAlign:'center'}}>Дата</div>
            <div className="mt-1" style={{width:150,textAlign:'center'}}>Номер запроса</div>
            <div className="mt-1" style={{width:170, textAlign:'center'}}>Запрос от</div>
            <div className="mt-1" style={{width:170, textAlign:'center'}}>Запрос</div>
        </div>
        <div className="flex-column d-flex" >
            {user.getCompleatedTasksForWitch().slice(0).reverse().map(t=>{
                return <CompleatedTaskForWitch
                    key={t.taskId}
                    createdAt={t.createdAt}
                    authorId={t.authorId}
                    authorName={t.authorName}
                    authorPicId={t.authorPicId}
                    taskId={t.taskId}
                    isTaskCompleated={t.isTaskCompleated}
                    task={t.task}
                    taskType={t.taskType}
                    updatedAt={t.updatedAt}
                    linkToPrivateRoom={t.linkToPrivateRoom}
                />

            })}
        </div>

        </div>
            <Pagination className="mt-4 ml-4" >
                <Pagination.Item
                    key='начало'
                    active={false}
                    activeLabel=''
                    onClick={()=>user.setPageWitchTasks(1)}
                > Начало
                </Pagination.Item>
                {pages.filter((item, index, arr)=>
                {return index<(user.getPageWitchTasks()+3)&&index>=user.getPageWitchTasks()-3} )
                    .map(page=>
                        <Pagination.Item
                            key={page}
                            active={user._pageWitchTasks === page}
                            activeLabel=''
                            onClick={()=>user.setPageWitchTasks(page)}
                        >
                            {page}
                        </Pagination.Item>
                    )}
                <Pagination.Item
                    key='...'
                    active={false}
                    activeLabel=''
                    onClick={()=>user.setPageWitchTasks(user.getPageWitchTasks()+3)}
                > ...
                </Pagination.Item>
                <Pagination.Item
                    key='конец'
                    active={false}
                    activeLabel=''
                    onClick={()=>user.setPageWitchTasks(pageCompleatedTasksCount)}
                > Конец
                </Pagination.Item>
            </Pagination>
        </div>


    </Row>


    );
}})


export default Witch;