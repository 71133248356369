import React from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {bottom} from "@popperjs/core";
import AccountCircul from "./AccountCircul";
import AccountMenu from "./AccountMenu";

const AccountCorner = () => {
    return (
        <OverlayTrigger
            trigger="click"
            rootClose
            key={bottom}
            placement={bottom}
            overlay={
                <Popover
                    style={{width:250, offset: 400, }}
                    id={`popover-positioned-${bottom}`}>
                    <AccountMenu/>
                </Popover>
            }
        >
           <div><AccountCircul/></div>
        </OverlayTrigger>






    );
};

export default AccountCorner;