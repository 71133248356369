import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import MobilePrivateGadanie from "../components/mobile/MobilePrivateGadanie/MobilePrivateGadanie";
import GptTaroLending from "../components/gpt-taro/gpt-taro-lending";
import NavBar from "../components/NavBar/NavBar";
import {Button, Image} from "react-bootstrap";
import {Link} from "react-scroll";
import MobileGptTaro from "../components/gpt-taro/mobile-gpt-taro";

const GptTaro = () => {

    const [width, setWidth] = useState(window.innerWidth)
    const updateWidth = () => {setWidth(window.innerWidth)}
    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    },[])



    if(width<500){
        return <MobileGptTaro/>;

    }else {
        return <MobileGptTaro/>;
    }




};

export default GptTaro;