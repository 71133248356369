import {makeAutoObservable} from "mobx";


export default class WidthStore{

    _width: number

    constructor() {
        this._width = 1500
        makeAutoObservable(this)
    }

    setWidth(width:number){
        this._width= width
    }

    getAvatarSelected(){
        return this._width
    }
}