import React, {  useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import MobilePrivateGadanie from "../components/mobile/MobilePrivateGadanie/MobilePrivateGadanie";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {MAIN_ROUTE} from "../utils/consts";


const MobilePrivateFirst= observer(() => {

    const [width, setWidth] = useState(window.innerWidth)
    const updateWidth = () => {setWidth(window.innerWidth)}
    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    },[])



if(width<500){
            return (
                <div className="mobMain d-flex flex-column"
                     style={{
                         position:"fixed",
                         overflow:"hidden",
                         height:"calc(100vh - calc(100vh - 100%))",
                         width:"100vw",
                         background:"black",
                         padding:0,
                     }}>

                    <MobilePrivateGadanie/>
                </div>


            );

        }else {
            return  (
                <div className="mobMain d-flex flex-column"
                     style={{
                         position:"fixed",
                         overflow:"hidden",
                         height:"calc(100vh - calc(100vh - 100%))",
                         width:"100vw",
                         padding:0,
                     }}>
                    <div
                        className="d-flex flex-row justify-content-center align-items-center"
                        style={{
                            height:"100vh",
                            width:350,
                            position:"absolute",
                            top:0,
                            left:"45%",
                            background:"black",
                        }}
                    >

                        <MobilePrivateGadanie/>
                    </div>
                </div>
            );
        }



});

export default MobilePrivateFirst;