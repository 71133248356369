import React, {useContext} from 'react';
import {Image} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";


const AvatarSelect = observer(() => {
const {avatar_selected} = useContext(Context)
const change_selected_avatar=(avatar_id:number)=>{
    const buffer_for_avatar = avatar_selected.getAvatarSelected()
    buffer_for_avatar.map(a=>a.avatar_is_selected=false)
    buffer_for_avatar.map(a=>a.avatar_id==avatar_id?a.avatar_is_selected=true:a.avatar_is_selected=false)
    avatar_selected.setAvatarSelected(buffer_for_avatar)
}

    return (
        <div style={{width:250}}  className="d-flex flex-wrap flex-row justify-content-end">
            {avatar_selected.getAvatarSelected().map((a)=>{return (
                <div key={a.avatar_id}>
                <Image width={80}
                       height={80}
                       alt="Пользователь гадания таро по видео"
                       src={a.image_source}
                       style={{zIndex:3, cursor:"pointer"}}
                       onClick={()=>change_selected_avatar(a.avatar_id)}
                        />
                    {a.avatar_is_selected?
                <Image
                        alt="Круг гадания таро по видео"
                        src={`${process.env.REACT_APP_STAT_URL}/circul.png`}
                        style={{ zIndex:1, marginLeft:-80}}
                        width={80}
                        height={80}

                /> :<div></div>}
                </div>
            )})}
        </div>
    );
});

export default AvatarSelect;