import React, {FC, useContext} from 'react';
import {Button, Form, Image, OverlayTrigger, Popover} from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import {Context} from "../../index";
import {WebSocketContext} from "../../store/WsContext";
import {observer} from "mobx-react-lite";
import {metrika} from "../../utils/metrika";


const MainChatInput:FC = observer(() => {

    const {steam} = useContext(Context)
    const {user} = useContext(Context)
    const {ws, createMessage} = useContext(WebSocketContext)

    const onEmojiClick = (emojiObject:string)=>{
        const prev = steam.getMessageInInput()
        steam.setMessageInInput(prev+emojiObject)
    }


    const sendTextToChat = async () =>{
        metrika('reachGoal','SendMessage')
        if(user.getIsAuth()){
            createMessage(steam.getMessageInInput(),'general', user.getUser().userpicId,user.getUser().userName)
        }else
        {
            user.setRegistrationModalShow(true)
            steam.setMessageInInput('')
        }

    }


    return (
            <div className='d-flex flex-column' style={{width: '100%',}}>
            <div className="d-flex flex-row justify-content-between" style={{width: '100%',}}  >
                <Form style={{width: '75%'}}
                      onSubmit={e=>{
                          e.preventDefault()
                          sendTextToChat()
                      }}>
                    <Form.Group>
                        <Form.Control type="text"
                                      placeholder="Нажмите, чтобы начать общаться.."
                                      style={{boxShadow:"none", fontSize:11, height:38}}
                                      onChange={(e)=>steam.setMessageInInput(e.target.value)}
                                      value={steam.getMessageInInput()}
                                      //disabled={!user.getIsAuth()}
                        />
                    </Form.Group>
                </Form>


                <OverlayTrigger
                    trigger="click"
                    rootClose
                    key='top'
                    overlay={
                        <Popover>
                            <EmojiPicker height={400}
                                         width={300}
                                         onEmojiClick={(obj, event)=>onEmojiClick(obj.emoji)
                            }
                            />
                        </Popover>
                    }
                >
                    <Button
                        variant="outline-secondary"
                        style={{ border:"none", boxShadow:"none", accentColor:"red"}}
                    >
                        <Image
                                alt="Гадание таро по видео"
                               width={20}
                               height={20}
                               src={`${process.env.REACT_APP_STAT_URL}/smile.png`}/>
                    </Button>
                </OverlayTrigger>


                <Button variant="outline-secondary  "
                        type="submit"
                        style={{width: '25%'}}
                        onClick={()=>sendTextToChat()}
                >
                    Отправить
                </Button>


            </div>
            </div>
    );
});

export default MainChatInput;

