import React, {useContext} from 'react';
import {Image} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const AccountCurrentAmoountOfIzumrudsAndCoins = observer(() => {
    const {user} =useContext(Context)
    return (
            <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-center" style={{width:250}}>
                    <div className="d-flex flex-row flex-nowrap align-content-center justify-content-center" style={{width:140}}>
                        <Image className="moneta "
                               style={{zIndex:1, marginLeft:-40}}
                               width={80}
                               height={80}
                               alt="Количество денег на балансе гадания таро"
                               src= {`${process.env.REACT_APP_STAT_URL}/coin.webp`}/>
                        <div className="d-flex flex-row justify-content-around align-content-around"
                             style={{zIndex:2, marginLeft:-66, width:74}}>
                                    <span style={{
                                        fontSize:24,
                                        marginTop:20,
                                        marginLeft:-20,
                                        color: "darkslategrey"    }}>{user.getUser().userCoinCount}
                                    </span>
                        </div>
                    </div>
                </div>

            </div>

    );
});

export default AccountCurrentAmoountOfIzumrudsAndCoins;