import React, {FC, useContext} from 'react';
import Card from "react-bootstrap/Card";
import {Button} from "react-bootstrap";
import {iTaskForWitch} from "../../store/ListOfSteamsStore";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {WebSocketContext} from "../../store/WsContext";



const TaskForWitch:FC<iTaskForWitch> = observer((props:iTaskForWitch) => {

    const {user} = useContext(Context)
    const {sendRequestToUserForProvatnoeGadanie} = useContext(WebSocketContext)

    return (
        <Card style={{ width: 300, marginRight:10, borderColor:((props.taskType==='private')?"red":"blue") }}>
            <Card.Body>
                <Card.Title>Задание от {props.authorName}</Card.Title>
                <h6>Время задания {props.createdAt}</h6>
                <textarea
                    readOnly={true}
                    value={props.task}
                    style={{
                        overflowY:"auto",
                        width:'100%',
                        height:200,
                        overflow:"scroll",
                        resize:"none",
                    }}>

                        </textarea>



                <div className="d-flex flex-column justify-content-between"
                     style={{margin:15}}>
                    <div>
                        {props.taskType==='private'?<a href={`/privateGadanie/${props.linkToPrivateRoom}`}
                        >Нажмите на ссылку, чтобы перейти в приватный чат с {props.authorName}</a>:null}

                    </div>
                    <Button
                        variant="primary"
                        onClick={()=>{
                            const index = user.getTasksForWitch().findIndex(t=>t.taskId===props.taskId)
                            user.setTaskCompleated(user.getTasksForWitch()[index].taskId)
                        }}
                    >Выполнено</Button>
                </div>
            </Card.Body>
        </Card>
    );
});

export default TaskForWitch;