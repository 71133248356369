import React, {FC, useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {IPrivateChannels} from "../MobilePrivateGadanie";
import {Context} from "../../../../index";
import {MAIN_ROUTE} from "../../../../utils/consts";
import YouPlaceInLine from "../../../LineOfGadaniya/YouPlaceInLine";
import VideoPlayerInPrivateChat from "../../../VideoComponentsForPrivate/VideoPlayerInPrivateChat";
import StreamerVideoPlayer from "../../../VideoComponentsForPrivate/StreamerVideoPlayer";
import MobileVideoPlayerInPrivateChat from "../MobileVideo/MobileVideoPlayerInPrivateChat";
import MobileStreamerVideoPlayer from "../MobileVideo/MobileStreamerVideoPlayer";
import MobileMainChat from "../../mobileChatButton/MobileMainChat";
import MobilePrivateChat from "../MobilePrivateChat";




const MobileUserPrivateComponent:FC<IPrivateChannels> = observer(({witchChannel,userChannel}:IPrivateChannels) => {
    const {user,steam} = useContext(Context)
    const [loaded,SetLoaded] = useState(false)
    const navigate = useNavigate()
  //  const witch = steam.getSteamArr().filter(e=>e.witchId===id)[0]


    useEffect(()=>{

            if(user._waitingRequestWitch===false && typeof user.getUser().userId ==='number'){
                SetLoaded(true)
            }
        }
        ,[steam._steamArr,user._user.userId])

    useEffect(() => {
        if(loaded){
           // console.log('loaded')
            if (user._isAuth===false){
               // console.log(user.getIsAuth())
                navigate(MAIN_ROUTE)
            }
        }
    },[loaded])


    return (
        <div style={{
            fontFamily:"Montserrat",
            color:"white",
            height:"100vh",
            width:"100%"
        }}>

            <div className="d-flex flex-row flex-wrap">
                <div style={{
                    height:"100vh",
                    width:"100%",
                    background:"black",
                }}>
                    <MobileVideoPlayerInPrivateChat room={witchChannel}/>
                </div>

                <div style={{
                    color:"black",
                    zIndex:3,
                    position:"absolute",
                    height:"25vh",
                    width:"98%",
                    marginLeft:"1%",
                    background:"white",
                    bottom:80
                }}>
                    <MobilePrivateChat/>
                </div>

                <div style={{
                    position:"absolute",
                    zIndex:4,
                    top:70,
                    right:10,
                    background:"lightyellow",
                    height:250,
                    maxWidth:200
                }}>
                    <MobileStreamerVideoPlayer room={userChannel}/>
                </div>

            </div>
        </div>
    );
});

export default MobileUserPrivateComponent;