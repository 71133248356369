import React, {FC, useEffect, useRef, useState} from 'react';
import {Button, Image} from "react-bootstrap";
import {IonSFUJSONRPCSignal} from "ion-sdk-js/lib/signal/json-rpc-impl";
import {Client} from "ion-sdk-js";
import {observer} from "mobx-react-lite";
import './mobileOffLineCover.css'

interface IVideoPlayerInPrivateChat{
    room:string
}

const MobileVideoPlayerInPrivateChat:FC<IVideoPlayerInPrivateChat>= observer( ({room}) => {
    const [isOnLine, SetIsOnLine] = useState(false)
    const [audio, SetAudio] = useState(false)
    const [audioTrackExist, SetAudioTrackExist]= useState<boolean>()
    const subVideo = useRef<HTMLVideoElement>(null);

    let client:any, signal;
    const [cl,SetCl] = useState<Client>()

    useEffect(() => {
        if(cl){
            cl.leave()
           // console.log('leave client')
            if(subVideo.current){
                subVideo.current.srcObject = null
                subVideo.current.autoplay = true;
              //  console.log('audio in useeffect '+audio)
                subVideo.current.muted = !audio;
                subVideo.current.style.position = 'absolute'
                SetIsOnLine(false)
            }

        }
        signal = new IonSFUJSONRPCSignal(`${process.env.REACT_APP_API_ION}`);
        client = new Client(signal);
        SetCl(client)

        signal.onopen = () => {
            client.join(room);
          //  console.log('signal on open')
        }

        client.ontrack = (track:any, stream:MediaStream) => {
          //  console.log("got track: ", track.id, "for stream: ", stream.id);
            track.onunmute = () => {
              //  if(track.kind==='audio'){
                    SetAudioTrackExist(true)
             //       SetAudio(false)
                //    console.log('setted audio to: '+audio)
                //}
                if(subVideo.current){
                    subVideo.current.srcObject = null
                    subVideo.current.autoplay = true;
                    subVideo.current.style.position = 'absolute'
                    SetIsOnLine(false)
                }

                if(subVideo.current){
                    subVideo.current.srcObject = stream;
                    subVideo.current.muted = !audio;
                    subVideo.current.autoplay = true;
                    subVideo.current.style.position = 'relative'
                    SetIsOnLine(true)
                }

                stream.onremovetrack = () => {
                    SetAudioTrackExist(false)
                    SetAudio(false)
                    if(subVideo.current) {
                        subVideo.current.srcObject = null;
                        subVideo.current.muted = !audio
                    }
                    SetIsOnLine(false)
                }

            }
            track.onmute=()=>{
                SetAudioTrackExist(false)
                SetAudio(false)
             //   console.log('muted')
                if(subVideo.current) {
                    subVideo.current.style.position = 'absolute'
                    subVideo.current.muted = !audio
                }
                SetIsOnLine(false)
            }
        }

    },[]);

    useEffect(()=>{
        if(subVideo.current) {
            subVideo.current.muted = !audio;}

    },[audio])

    const audioChange=()=> {
        SetAudio(!audio)
        if(subVideo.current){
            subVideo.current.muted = audio;
        }
    }

    return (
        <div
            className='d-flex flex-column'
            style={{
                overflow:"hidden"
            }}>

            <video
                style={{
                    height:'70vh',
                    marginLeft:"auto",
                    marginRight:"auto",
            }}
                autoPlay
                className="mobpubVideo"
                ref={subVideo}
            >
            </video>


            {(!isOnLine)?
                <div
                    className="d-flex flex-row justify-content-center align-content-end"
                >
                    <div style={{

                        position:"absolute",
                        top:"40%",
                        left:"15%",
                        width:270,
                        height:70,
                        fontSize:16,
                        marginTop:'20%',
                        textAlign:"center"
                    }}>
                        Собеседник не включил камеру
                    </div>
                </div>:
                <div
                    className="d-flex justify-content-between"
                    style={{
                        zIndex:2,
                        marginTop:-40,
                        color:"white",
                        fontSize:20,
                        fontFamily:"Montserrat",
                        paddingRight:5,
                    }}>
                    {audioTrackExist?<Button
                        style={{
                            position:"absolute",
                            top:60,
                            background:"none",
                            border:"none",
                            width:60,
                        }}  onClick={audioChange}>
                        <Image
                            alt="Гадание таро по видео"
                            style={{
                                width:40,
                                height:40,
                                background:"red",
                                borderRadius:10,
                                opacity:0.5
                            }}
                            src={!audio ?
                                `${process.env.REACT_APP_STAT_URL}/speakeroff.png`:
                                `${process.env.REACT_APP_STAT_URL}/speakeron.png`}/>
                    </Button>:<div
                        style={{
                            color:"black",
                            background:"white",
                            opacity:0.5,
                            borderRadius:8,
                        }}>Ведущий отключил звук</div>}

                </div>}


        </div>


    );
});

export default MobileVideoPlayerInPrivateChat;