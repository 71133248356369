import React, {FC, useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {IPrivateChannels} from "../MobilePrivateGadanie";
import {Context} from "../../../../index";
import {MAIN_ROUTE} from "../../../../utils/consts";
import VideoPlayerInPrivateChat from "../../../VideoComponentsForPrivate/VideoPlayerInPrivateChat";
import StreamerVideoPlayer from "../../../VideoComponentsForPrivate/StreamerVideoPlayer";
import MobileStreamerVideoPlayer from "../MobileVideo/MobileStreamerVideoPlayer";
import MobileVideoPlayerInPrivateChat from "../MobileVideo/MobileVideoPlayerInPrivateChat";
import MobilePrivateChat from "../MobilePrivateChat";



const MobileWitchPrivateComponent:FC<IPrivateChannels> = ({witchChannel,userChannel}:IPrivateChannels) => {
    const {user,steam, privateChat} = useContext(Context)
    const [loaded,SetLoaded] = useState(false)
    const navigate = useNavigate()

    useEffect(()=>{
        steam.setWitchPrivateStatus(true)
    },[])

    useEffect(()=>{
            if(user._waitingRequestWitch===false && typeof user.getUser().userId ==='number'){
                SetLoaded(true)
            }
        }
        ,[steam._steamArr,user._user.userId])

    useEffect(() => {
        if(loaded){
           // console.log('loaded')
            if (user._isAuth===false){
                //console.log(user.getIsAuth())
                navigate(MAIN_ROUTE)
            }
        }
    },[loaded])


    return (
        <div style={{
            fontFamily:"Montserrat",
            color:"white",
            height:"100vh",
            width:"100%"
        }}>

            <div className="d-flex flex-row flex-wrap">
                <div style={{
                    height:"100vh",
                    width:"100%",
                    background:"black",
                }}>

                    <MobileVideoPlayerInPrivateChat room={userChannel}/>
                </div>


                <div style={{
                    color:"black",
                    zIndex:3,
                    position:"absolute",
                    height:"25vh",
                    width:"98%",
                    marginLeft:"1%",
                    background:"white",
                    bottom:80
                }}>
                    <MobilePrivateChat/>
                </div>

                <div style={{
                    position:"absolute",
                    zIndex:4,
                    top:70,
                    right:10,
                    opacity:0.8,
                    background:"lightyellow",
                    height:250,
                    maxWidth:200
                }}>
                    <MobileStreamerVideoPlayer room={witchChannel}/>
                </div>

            </div>

        </div>
    );
};

export default MobileWitchPrivateComponent;