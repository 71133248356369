import $api from "../http/forTraining";
import {AxiosResponse} from "axios";


export default class PrivateChatService{

    static async checkAreYouInRoom(link:string|undefined):Promise<AxiosResponse<{areYouInRoom: boolean}>>{
        return $api.post('/private-room/checkIsInRoom', {link:link})
    }


}