import React, {FC} from 'react';
import { Image} from "react-bootstrap";
import {observer} from "mobx-react-lite";


interface iCoinBay{
    count_of_coins:number,
    price: number,
    discount_in_percent: number,
    price_before_discount:number,
    description:string
}
const ForPrice:FC<iCoinBay> = observer(({count_of_coins, price,discount_in_percent,price_before_discount, description}:iCoinBay) => {


    return (
        <div className="d-flex flex-column flex-nowrap justify-content-center" style={{ marginTop:4,width:150}}>

            <div className="d-flex flex-row flex-nowrap align-content-center justify-content-center">
                <Image className="moneta "
                       style={{zIndex:1}}
                       width={74}
                       height={74}
                       alt="Гадание таро по видео"
                       src={`${process.env.REACT_APP_STAT_URL}/coin.webp`}/>
                <div className="d-flex flex-row justify-content-around align-content-around"
                     style={{zIndex:2, marginLeft:-75, width:74}}>
                        <span style={{
                            fontSize:28,
                            marginTop:15,
                            marginLeft:5,
                            color: "darkslategrey"    }}>{count_of_coins}
                        </span>
                </div>
            </div>



            <div className="d-flex flex-row justify-content-center ml-2" style={{width:140}}>
                <div style={{color:"black", borderRadius:6}}>
                     <span
                         className="priceNow"
                         style={{fontFamily:"PT Sans", fontSize:16, fontWeight:"bold"}}>{price} &#8399;
                     </span>
                </div>

            </div>

        </div>
    );
});

export default ForPrice;