import {makeAutoObservable} from "mobx";
import { configure } from "mobx"
import {IMessage} from "../models/IMessage";
import {UserPic} from "../models/UserPic";
import WitchService from "../service/WitchService";


configure({
    enforceActions: "never",
})

export enum TypeOfMessageInChat {
    'general',
    'private',
    'paidButNotPrivate',
    'coinsGift'
}


export interface iTaskForWitch {
    createdAt:string,
    authorId:number,
    authorName:string,
    authorPicId:number,
    taskId:number,
    isTaskCompleated:boolean,
    task:string,
    taskType:string,
    updatedAt:string,
    linkToPrivateRoom:string
}


export interface ISteamers{
    witchNick:string,
    witchName:string,
    witchId:number,
    desctription:string,
    viewers:number
    linkToCover:string,
    isOnLine: boolean,
    isPrivate: boolean,
    isMicMuted: boolean,
    rating:number,
}


export default class ListOfSteamsStore {
    _messagesInChat: IMessage[]
    _waitingForChatUpdate: boolean = false
    _steamArr= {} as ISteamers[]
    _messageInInput: string= ''
    _inputResponseErrors = ''
    _questionToWitch= ''
    _privateQuestionToWitch= ''
    _currentWitchId = {} as number


    constructor() {

        this._messagesInChat = []
        makeAutoObservable(this)
    }






    _sortfunction(a:ISteamers, b:ISteamers){
        let c:number
        c=b.viewers-a.viewers
        return c
    }

    setQuestionToWitch(question:string){
        this._questionToWitch = question
    }

    getQuestionToWitch(){
        return this._questionToWitch
    }

    setPrivateQuestionToWitch(question:string){
        this._privateQuestionToWitch = question
    }

    getPrivateQuestionToWitch(){
        return this._privateQuestionToWitch
    }

    setInputResponseErrors(err:string){
        this._inputResponseErrors = err
    }

    getInputResponseErrors(){
        return this._inputResponseErrors
    }

    getSortedWitches(){
        return this.getSteamArr()
    }

    setSteamArr(steamers: ISteamers[]){
        this._steamArr = steamers
    }

    getSteamArr(){
        return this._steamArr
    }


    setMessagesInChat(messages: IMessage[]){
        this._messagesInChat = messages
    }

    setMessageInInput(message: string){
        this._messageInInput = message
    }

    setCurrentWitchId(witchId: number){
        this._currentWitchId = witchId
    }


    getMessageInInput(){
        return this._messageInInput
    }

    getMessagesInChat(){
        return this._messagesInChat
    }

    getUserPic(picNumber: number) {
        return UserPic[picNumber]
    }
    getCurrentWitchId(){
        return this._currentWitchId
    }

    setOnLineWitchStatus(isOnLine:boolean){
       // console.log('id: ' + this.getCurrentWitchId() +' isOnLine: ' +isOnLine)
        WitchService.setOnLineWitchStatus({witchId:this.getCurrentWitchId(),isOnLine:isOnLine})
    }

    setWitchPrivateStatus(isPrivate:boolean){
        // console.log('id: ' + this.getCurrentWitchId() +' isOnLine: ' +isOnLine)
        WitchService.setPrivateWitchStatus({isPrivate})
    }
}
