import {makeAutoObservable} from "mobx";
import AuthService from "../service/AuthService";
import axios from "axios";
import {AuthRefreshResponse} from "../models/response/AuthResponse";
import UserService from "../service/UserService";
import {ICoins, IDiamonds} from "../models/IUser";
import {UserPic} from "../models/UserPic";
import ListOfSteamsStore, {iTaskForWitch} from "./ListOfSteamsStore";
import StreamsService from "../service/StreamsService";
import {ws} from "./WsContext";
import PrivateChatStore from "./PrivateChatStore";

export interface IUser{
    userId:number,
    userEmail:string,
    userName:string,
    userPassword:string,
    userpicId:number,
    userCoinCount:number,
    userIzumrudCount:number,
    isFirstTimeAfterActivation:boolean
}


export default class UserStore {

    _isAuth: boolean
    _isLoading: boolean = false
    _user = {} as IUser
    _waitingRequestChangeAvatar = false
    _waitingRequestChangePassword = false
    _waitingRequestChangeName = false
    _waitingRequestExchangeDiamonds = false
    _waitingRequestWitch = true
    _diamonds = [{}] as IDiamonds[]
    _coins = [{}] as ICoins[]
    _countOfDiamonsTransations:number
    _pageDimond:number
    _countOfCoinsTransations:number
    _pageCoins:number
    _pageWitchTasks:number
    _registrationModalShow:boolean
    _afterRegistrationModalShow:boolean
    _currentGadaniyeUser = {} as {userpicId:number, userName:string}
    _lineForGadanie = {} as {indexes:number[]}
    _paramQuery:string
    _notEnoughtCoins:boolean
    _technicalOpenModal:boolean
    _PingWS:boolean
    _compleatedTasksForWitch:iTaskForWitch[]=[
        {
            createdAt:'',
            authorId:1,
            authorName:'',
            authorPicId:1,
            taskId:1,
            isTaskCompleated:true,
            task:'',
            taskType:'',
            updatedAt:'',
            linkToPrivateRoom:''
        }
    ]
    _tasksForWitch: iTaskForWitch[]
    _countOfCompleatedTasksForWitch:number
    _isPendingPrivatChat:boolean
    _linksToPrivatChat: { linkToPrivateRoom:string
                          witchName:string }[]
    _isInPrivateChat: boolean
    _sound:string
    _finallyUpdatedName:string


    constructor(
        private steamsStore:ListOfSteamsStore,
        private privateChatStore:PrivateChatStore
    ) {
        this._isAuth = false
        this._waitingRequestChangeAvatar = false
        this._waitingRequestChangeName = false
        this._waitingRequestChangePassword = false
        this._countOfDiamonsTransations = 0
        this._pageDimond = 1
        this._countOfCoinsTransations = 0
        this._pageCoins = 1
        this._pageWitchTasks = 1
        this._user = {} as IUser
        this._countOfCompleatedTasksForWitch = 0
        this._registrationModalShow =false
        this._afterRegistrationModalShow = false
        this._notEnoughtCoins=false
        this._technicalOpenModal=false
        this._tasksForWitch =[
            {   createdAt:'',
                authorId:0,
                authorName:'',
                authorPicId:1,
                taskId:1,
                isTaskCompleated:false,
                task:'',
                taskType:'',
                updatedAt:'',
                linkToPrivateRoom:''
            }
        ]
        this._paramQuery = ''
        this._isPendingPrivatChat = false
        this._linksToPrivatChat = []
        this._isInPrivateChat = false
        this._PingWS = false
        this._sound = ''
        this._finallyUpdatedName=''

        makeAutoObservable(this)
    }

    setSound(soundSTRING:string) {
        this._sound = soundSTRING
    }

    getSound() {
        return this._sound
    }


    setPingWS(bool:boolean) {
        this._PingWS = bool
    }

    getPingWS() {
        return this._PingWS
    }

    setIsInPrivateChat(bool:boolean) {
        this._isInPrivateChat = bool
    }

    getIsInPrivateChat() {
        return this._isInPrivateChat
    }


    setLinksToPrivatChat(links:{ linkToPrivateRoom:string
        witchName:string }[]) {
        this._linksToPrivatChat = links
    }

    getLinksToPrivatChat() {
        return this._linksToPrivatChat
    }

    setIsPendingPrivatChat(bool: boolean) {
        this._isPendingPrivatChat = bool
    }

    getIsPendingPrivatChat() {
        return this._isPendingPrivatChat
    }

    setIsAuth(bool: boolean) {
        this._isAuth = bool
    }

    setPageDiamonds(page: number) {
        this._pageDimond = page
    }

    setPageCoins(page: number) {
        this._pageCoins = page
    }


    setPageWitchTasks(page: number) {
        this._pageWitchTasks = page
    }

    setCountOfDiamonsTransations(count: number) {
        this._countOfDiamonsTransations = count
    }

    setCountOfCoinsTransations(count: number) {
        this._countOfCoinsTransations = count
    }



    setCountOfCompleatedTasksForWitch(count: number) {
        this._countOfCompleatedTasksForWitch = count
    }


    setIsLoading(bool: boolean) {
        this._isLoading = bool
    }
    setUser(user: IUser) {
        this._user = user
    }

    setDiamonds(diamonds: IDiamonds[]) {
        this._diamonds = diamonds
    }

    setCoins(coins: ICoins[]) {
        this._coins = coins
    }


    setCompleatedTasksForWitch(tasks:iTaskForWitch[]){
        this._compleatedTasksForWitch = tasks
    }

    setRegistrationModalShow(status:boolean){
        this._registrationModalShow = status
    }


    setAfterRegistrationModalShow(status:boolean){
        this._afterRegistrationModalShow = status
    }

    setParamQuery(param:string){
        this._paramQuery = param
    }



    setTechnicalOpenModal(technicalOpenModal:boolean){
        this._technicalOpenModal = technicalOpenModal
    }

    getTechnicalOpenModal(){
        return this._technicalOpenModal
    }

    setNotEnoughtCoins(notEnoughtCoins:boolean){
        this._notEnoughtCoins = notEnoughtCoins
    }

    getNotEnoughtCoins(){
        return this._notEnoughtCoins
    }

    getParamQuery(){
        return this._paramQuery
    }

    getAfterRegistrationModalShow(){
        return this._afterRegistrationModalShow
    }

    getCompleatedTasksForWitch(){
        return this._compleatedTasksForWitch
    }

    getIsAuth() {
        return this._isAuth
    }

    getPageDiamonds() {
        return this._pageDimond
    }

    getPageCoins() {
        return this._pageCoins
    }

    getPageWitchTasks() {
        return this._pageWitchTasks
    }

    getCountOfDiamonsTransations() {
        return this._countOfDiamonsTransations
    }

    getCountOfCoinsTransations() {
        return this._countOfCoinsTransations
    }

    getCountOfCompleatedTasksForWitch() {
        return this._countOfCompleatedTasksForWitch
    }

    getUser() {
        return this._user
    }

    getDiamonds(){
        return this._diamonds
    }

    getCoins(){
        return this._coins
    }

    getUserPic() {
        return UserPic[this._user.userpicId]
    }

    getRegistrationModalShow(){
        return this._registrationModalShow
    }

    getLineForGadanie(){
        return this._lineForGadanie
    }

    getCurrentGadaniyeUser(){
        return this._currentGadaniyeUser
    }


    setLineForGadanie(lineForGadanie:{indexes:number[]}){
        this._lineForGadanie = lineForGadanie
    }

    setCurrentGadaniyeUser(currentGadaniyeUser:{userpicId:number,userName:string}){
        return this._currentGadaniyeUser = currentGadaniyeUser
    }


    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password)
            localStorage.setItem('token', response.data.access_token)
            await this.updateAllfields(response)

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }

    async registration(email: string, password: string) {
        try {
            const response = await AuthService.registration(email, password)
            localStorage.setItem('token', response.data.access_token)
            await this.updateAllfields(response)
            this.setAfterRegistrationModalShow(true)

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }

    async randomRegistration() {
        try {
            const response = await AuthService.randomRegistration()
            localStorage.setItem('token', response.data.access_token)
            await this.updateAllfields(response)
            this.setAfterRegistrationModalShow(true)

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
        return 'ok'
    }


    async registration_witch(email:string,userName:string,password:string,secondPassword:string,
                             accountPicNumber:number,description:string, pathLink: string) {

        if (password!==secondPassword) return 'пароли не совпадают'
        try {
            const response = await AuthService.registration_witch(email, userName, password,
                secondPassword, accountPicNumber,description, pathLink)
            localStorage.setItem('token', response.data.access_token)
            await this.updateAllfields(response)
            return response.status

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (Object.values(e.response.data)[0])
            } else {
                return (e.response.data.message)
            }
        }
    }


    async logout() {
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }
        try {
            await AuthService.logout()
        } catch (e: any) {
          //  console.log(e.response?.data?.message)
        }
        this.setUser({} as IUser)
        this.setIsAuth(false)
        this.setCountOfDiamonsTransations(0)
        this.setCountOfCoinsTransations(0)
        localStorage.removeItem('token')
    }

    async checkAuth() {
        this.setIsLoading(true)

        try {
            const response = await AuthService.checkAuth()
           // localStorage.setItem('token', response.data.access_token)
            await this.updateAllfields(response)
           // ws.io.opts.query ={
           //     jwtTokenFieldName:localStorage.getItem('token')
            //}
            //ws.disconnect().connect()
            //ws.emit('onLeftRoom')

          /*  if (this.getIsInPrivateChat()){
                if (this.getIsAuth()){
                    ws.emit('JoinPrivateRoom',{privateRoomLink:this.privateChatStore.getRoom(), userOrWitchId:this.getUser().userId})
                }
            }

            if (this.getIsAuth()){
                if (typeof this.steamsStore.getCurrentWitchId()==='number'){
                    ws.emit('JoinRoom',{witchId:this.steamsStore.getCurrentWitchId(), userId:this.getUser().userId})
                }else {
                    ws.emit('JoinRoom',{witchId:this.getUser().userId, userId:this.getUser().userId})
                }

            }else {
                if (typeof this.steamsStore.getCurrentWitchId()==='number'){
                    ws.emit('JoinRoom',{witchId:this.steamsStore.getCurrentWitchId(), userId:91})
                }else {
                    ws.emit('JoinRoom',{witchId:91, userId:91})
                }
            }
        */

        } catch (e: any) {
            await this.checkRefreshToken()
        } finally {
            this.setIsLoading(false)
        }
        return 'ok'
    }

    async checkRefreshToken() {
        try {

            const response = await axios.get<AuthRefreshResponse>(`${process.env.REACT_APP_API_URL}/auth/refresh`, {withCredentials: true})
            localStorage.setItem('token', response.data.access_token)
            await this.updateAllfields(response)
           ws.io.opts.query ={
                jwtTokenFieldName:localStorage.getItem('token')
            }
            ws.disconnect().connect()
            ws.emit('onLeftRoom')

            if (this.getIsInPrivateChat()){
                if (this.getIsAuth()){
                    ws.emit('JoinPrivateRoom',{privateRoomLink:this.privateChatStore.getRoom(), userOrWitchId:this.getUser().userId})
                }
            }

            if (this.getIsAuth()){
                if (typeof this.steamsStore.getCurrentWitchId()==='number'){
                    ws.emit('JoinRoom',{witchId:this.steamsStore.getCurrentWitchId(), userId:this.getUser().userId})
                }else {
                    ws.emit('JoinRoom',{witchId:this.getUser().userId, userId:this.getUser().userId})
                }

            }else {
                if (typeof this.steamsStore.getCurrentWitchId()==='number'){
                    ws.emit('JoinRoom',{witchId:this.steamsStore.getCurrentWitchId(), userId:91})
                }else {
                    ws.emit('JoinRoom',{witchId:91, userId:91})
                }
            }




        } catch (e: any) {
         //   console.log(e.response?.data?.message)
            this.setTechnicalOpenModal(true)
         //   console.log('refresh token fail')

        }
        return 'ok'
    }

    async changeUserName(newUserName: string) {

        this._waitingRequestChangeName = true
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.changeUserName(newUserName)
            this.setUser({...this._user, userName: response.data.userName})
            return response.status
        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }finally {
            this._waitingRequestChangeName = false
        }
    }


    async changeUserNameInMobile(newUserName: string) {

        this._waitingRequestChangeName = true
        try {
            const response = await UserService.changeUserName(newUserName)
            this.setUser({...this._user, userName: response.data.userName})
            return response.status
        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }finally {
            this._waitingRequestChangeName = false
            this._finallyUpdatedName = 'nameUpdated'
        }
        return 'ok'
    }





    async changeAccountPicNumber(accountPicNumber: number) {
        this._waitingRequestChangeAvatar = true
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.changeAccountPicNumber(accountPicNumber)
            this.setUser({...this._user, userpicId: response.data.accountPicNumber})
            return response.status
        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        } finally {
            this._waitingRequestChangeAvatar = false
        }
    }

    async changePassword(password:string, newPassword1:string, newPassword2:string,) {
        this._waitingRequestChangePassword = true
        try {
            AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.changePassword( password, newPassword1, newPassword2)
            return response.status

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }finally {
            this._waitingRequestChangePassword = false
        }
    }

    async deleteUser() {
        try {
            AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.deleteAccount()
            this.setUser({} as IUser)
            this.setIsAuth(false)
            return response.status
        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }

    async requestDiamonds() {
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.getDiamonds(6,this._pageDimond)
            const array = Object.values(response.data)
            array.map(t=>t.createdAt= t.createdAt.replace('T',' ').replace('Z','').split('.')[0])
            this.setDiamonds(array.reverse())

            const countOfDiamonsTransations = await UserService.getDiamondsTransactionsCount()
            this.setCountOfDiamonsTransations(countOfDiamonsTransations.data)


            return response.status

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }

    async requestCoins() {
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.getCoins(6,this._pageCoins)
            const array = Object.values(response.data)
            array.map(t=>t.createdAt= t.createdAt.replace('T',' ').replace('Z','').split('.')[0])
            this.setCoins(array.reverse())
            const countOfCoinsTransations = await UserService.getCoinsTransactionsCount()
            this.setCountOfCoinsTransations(countOfCoinsTransations.data)

            return response.status

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }

    async buyCoins(price:number, description:string, linkToPath:string|undefined) {
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
           // console.log('paymentsCreate')
            if (typeof linkToPath==='undefined'){linkToPath='user'}

            const response = await UserService.paymentsCreate(price,description, linkToPath)
          //  console.log(response)
            this.requestCoins()
            return response

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }

    async exchangeDiamonds(transaction:number) {
        this._waitingRequestExchangeDiamonds = true
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.exchangeDiamonds(transaction, 'Покупка')
            this.requestCoins()
            this.requestDiamonds()

            return response.status

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (Object.values(e.response.data)[0])
            } else {
                return (e.response.data.message)
            }
        } finally {
            this._waitingRequestExchangeDiamonds = false
        }
    }


    async updateAllfields(response:any){
        const countOfDiamonsTransations = await UserService.getDiamondsTransactionsCount()
        const countOfCoinsTransations = await UserService.getCoinsTransactionsCount()
        const coinsCount = await UserService.getCoinsCount()
        const diamondsCount = await UserService.getDiamondsCount()

        this.setCountOfDiamonsTransations(countOfDiamonsTransations.data)
        this.setCountOfCoinsTransations(countOfCoinsTransations.data)
        this.setIsAuth(true)
        this.setUser({
            ...this._user,
            userId: response.data.userDTO.id,
            userEmail: response.data.userDTO.email,
            userName: response.data.userDTO.userName,
            userpicId: response.data.userDTO.accountPicNumber,
            userCoinCount:coinsCount.data.coins_count,
            userIzumrudCount:diamondsCount.data.diamons_count,
            isFirstTimeAfterActivation: response.data.userDTO.isFirstTimeAfterActivation
        })
    }



    async requestCompleatedTasks() {
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.getHistoryOfCompleatedTasks(6,this._pageWitchTasks)
            const array = Object.values(response.data)
            //array.map(t=>t.updatedAt= t.createdAt.replace('T',' ').replace('Z','').split('.')[0])
           // this.setCompleatedTasksForWitch(array.reverse())

          //  console.log(array.reverse())

            const bufferArr:iTaskForWitch[] =[]
            Object.values(array).forEach((t:any)=>{
                    bufferArr.push(
                        {
                            createdAt:t.createdAt.replace('T',' ').replace('Z','').split('.')[0],
                            authorId:t.authorId,
                            authorName:t.authorName,
                            authorPicId:t.authorPicId,
                            taskId:t.id,
                            isTaskCompleated:t.isTaskCompleated,
                            task:t.task,
                            taskType:t.taskType,
                            updatedAt:t.updatedAt.replace('T',' ').replace('Z','').split('.')[0],
                            linkToPrivateRoom:t.linkToPrivateRoom
                        })
                }
            )
            this.setCompleatedTasksForWitch(bufferArr)

            const countOfCompleatedTasks = await UserService.get_compleated_tasks_count()
            this.setCountOfCompleatedTasksForWitch(countOfCompleatedTasks.data)



            return response.status

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }



    async setTaskCompleated(taskId:number) {
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.set_task_compleated(taskId,6,1)
            const arrayCoplm = Object.values(response.data)

            const bufferArr:iTaskForWitch[] =[]
            Object.values(arrayCoplm).forEach((t:any)=>{
                    bufferArr.push(
                        {
                            createdAt:t.createdAt.replace('T',' ').replace('Z','').split('.')[0],
                            authorId:t.authorId,
                            authorName:t.authorName,
                            authorPicId:t.authorPicId,
                            taskId:t.id,
                            isTaskCompleated:t.isTaskCompleated,
                            task:t.task,
                            taskType:t.taskType,
                            updatedAt:t.updatedAt.replace('T',' ').replace('Z','').split('.')[0],
                            linkToPrivateRoom:t.linkToPrivateRoom
                        })
                }
            )
            this.setCompleatedTasksForWitch(bufferArr.splice(0).reverse())
            const countOfCompleatedTasks = await UserService.get_compleated_tasks_count()
          //  console.log('count: '+countOfCompleatedTasks.data)
            this.setCountOfCompleatedTasksForWitch(countOfCompleatedTasks.data)

            await this.requestPendingTasks()


            return response.status



        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }





    setTasksForWitch(tasks:iTaskForWitch[]){
        this._tasksForWitch = tasks
    }


    getTasksForWitch(){
        return this._tasksForWitch
    }



    async requestPendingTasks() {
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.getPendingTasks()

            const array = Object.values(response.data)
            const bufferArr:iTaskForWitch[] =[]
             Object.values(array).forEach((t:any)=>{
                    bufferArr.push(
                        {
                            createdAt:t.createdAt.replace('T',' ').replace('Z','').split('.')[0],
                            authorId:t.authorId,
                            authorName:t.authorName,
                            authorPicId:t.authorPicId,
                            taskId:t.id,
                            isTaskCompleated:t.isTaskCompleated,
                            task:t.task,
                            taskType:t.taskType,
                            updatedAt:t.updatedAt.replace('T',' ').replace('Z','').split('.')[0],
                            linkToPrivateRoom:t.linkToPrivateRoom
                        })
                }
            )

            this.setTasksForWitch(bufferArr.splice(0).reverse())
            return response.status

        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }
    }



    async getWitch() {
        this._waitingRequestWitch = true

        try {
            const response = await StreamsService.getWitch()
            this.steamsStore.setSteamArr(response.data)
         //   console.log(response.data)
            return response.status
        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        } finally {
           // console.log('finally')
            this._waitingRequestWitch = false
        }
    }


    async changeIsFirstTimeAfterActivation() {
        this._waitingRequestChangeName = true
        try {
            await AuthService.checkAuth()
        }
        catch (e:any) {
            if (e.response.status === 401) {
                await this.checkRefreshToken()
            }
        }

        try {
            const response = await UserService.changeIsFirstTimeAfterActivation()
            this.setUser({...this._user, isFirstTimeAfterActivation: false})
            return response.status
        } catch (e: any) {
            if (typeof e.response.data.message === 'undefined') {
                return (e.response.data)
            } else {
                return (e.response.data.message)
            }
        }finally {
            this._waitingRequestChangeName = false
        }
    }

}
