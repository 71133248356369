import {makeAutoObservable} from "mobx";
import PrivateChatService from "../service/PrivateChatService";



export default class PrivateChatStore{

    _message =''
    _isRoomLoaded= false
    _room = ''
    _isUserOrWitchInRoom = false

    constructor() {
        makeAutoObservable(this)
    }


    setRoom(room:string){
        this._room = room
    }

    getRoom(){
        return this._room
    }


    setIsRoomLoaded(isRoomLoaded:boolean){
        this._isRoomLoaded= isRoomLoaded
    }

    getIsRoomLoaded(){
        return this._isRoomLoaded
    }

    async checkIsUserOrWitchInRoom(link:string|undefined){
        const res = await PrivateChatService.checkAreYouInRoom(link)
        return res
    }



}
