import {makeAutoObservable} from "mobx";
import {IPrice} from "../models/IUser";
import PriceService from "../service/PriceService";


export default class PriceStore{

    _prices ={} as IPrice[]
    _isPriceLoaded= false

    constructor() {
        makeAutoObservable(this)
    }

    setPrice(price:IPrice[]){
        this._prices= price
    }

    getPrice(){
        return this._prices
    }


    setIsPriceLoaded(isPriceLoaded:boolean){
        this._isPriceLoaded= isPriceLoaded
    }

    getIsPriceLoaded(){
        return this._isPriceLoaded
    }

    async udpatePrice(){
        const priceLine = await PriceService.getAllPriceLines()
        this.setPrice(priceLine.data)
        this.setIsPriceLoaded(true)
    }


}
