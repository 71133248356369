import $api from "../http/forTraining";
import {AxiosResponse} from "axios";
import {ISetWitchStatusIsOnLine} from "../models/ISetWitchStatusIsOnLine";

export default class WitchService{

    static async setOnLineWitchStatus(dto: ISetWitchStatusIsOnLine):Promise<AxiosResponse<ISetWitchStatusIsOnLine>>{
        return $api.post('/witch/setOnLineWitchStatus', dto)
    }

    static async setPrivateWitchStatus(dto:{isPrivate:boolean}):Promise<AxiosResponse<{witchId:number,isPrivate:boolean}>>{
        return $api.post('/witch/setPrivateWitchStatus', dto)
    }


}

