import React, {useContext,  useState} from 'react';
import { Form, Spinner} from 'react-bootstrap';
import {Button,  InputGroup} from "react-bootstrap";
import '../../components/FastActions/FastAction.css'
import AvatarSelect from "../../components/AvatarSelect/AvatarSelect";
import LightGrayLine from "../../components/LightGrayLine/LightGrayLine";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import CashIn from "../../components/CashIn/CashIn";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {MAIN_ROUTE} from "../../utils/consts";
import {useNavigate} from "react-router-dom";
import './user_accaunt.css'
import CoinsTable from "../../components/CoinsTable/CoinsTable";

const UserAccaunt = observer(() => {
    const [newUserName, SetNewUserName] = useState('')
    const [error, setError] = useState('')
    const [errorAvatar, setErrorAvatar] = useState('')
    const [errorDelete, setErrorDelete] = useState('')

    const {user, avatar_selected} = useContext(Context)
    user.setIsInPrivateChat(false)
    const navigate = useNavigate()

    if (user._isLoading){
        return <Spinner animation="border"/>
    }

    const changeName= async ()=> {
        setError(await user.changeUserName(newUserName))
    }

    const changeAvatarPic = async () =>{
        const selected_avatar = avatar_selected.getAvatarSelected().find(element=>element.avatar_is_selected===true)
        if (typeof selected_avatar!=="undefined"){
            setErrorAvatar(await user.changeAccountPicNumber(selected_avatar.avatar_id))
        }
    }

    const deleteUser = async () =>{
        setErrorDelete(await user.deleteUser())
        navigate(MAIN_ROUTE)
    }



    return (
        <div className="d-flex flex-column justify-content-between ml-3 mr-5" >

            <div className="mt-4">
                <span style={{fontSize:54}}>Здравствуйте, {user.getUser().userName} !</span>
            </div>

            <LightGrayLine/>

            <div className="d-flex justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{fontSize:24, width:210, marginRight:90, marginBottom:15}}>
                    Пополнить счёт
                </span>
                <CashIn/>
            </div>
            <LightGrayLine/>


            <div className="d-flex flex-column justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{fontSize:24, width:210, marginRight:90, marginBottom:15}}>
                    История начисления монет
                </span>
                <CoinsTable/>
            </div>
            <LightGrayLine/>

            {/*
            <div className="d-flex flex-column justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{fontSize:24, width:210, marginRight:90, marginBottom:15}}>
                    История начисления алмазов
                </span>
                <DiamondsTable/>
            </div>
            <LightGrayLine/>



             <div className="d-flex d-flex justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{fontSize:24, width:210, marginRight:90, marginBottom:15}}>
                    Продать алмазы
                </span>
                <CoinsAndCristalsOnAccount/>
            </div>
            <LightGrayLine/>*/}

            <div className="mt-4">
                <span style={{fontSize:54}}>Настройки</span>
            </div>

            <LightGrayLine/>
            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">
                <span className="text-nowrap"  style={{fontSize:24, width:200, marginRight:100, marginBottom:15 }}>
                    Поменять аватар
                </span>

                <div className="d-flex flex-column align-items-end" style={{width:300}}>
                    <AvatarSelect/>
                    <Button style={{width:200, marginTop:30}}
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={async ()=>{
                                await changeAvatarPic()
                                setTimeout(()=>setErrorAvatar(' '), 1000)
                            }}
                    >
                        Сохранить аватар
                    </Button>
                    {user._waitingRequestChangeAvatar===true? <Spinner animation="border"/>:<div></div>}
                    {errorAvatar==='201'?<div className="responseText">Аватар успешно изменён</div>:<div>{errorAvatar}</div>}
                </div>
            </div>
            <LightGrayLine/>

            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{fontSize:24, width:200, marginRight:100, marginBottom:15}}>Поменять имя</span>
                <div>
                    <InputGroup className="mb-3 d-flex flex-column align-items-end">
                        <Form.Control
                            placeholder="Новое имя"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            style={{width:300}}
                            value={newUserName}
                            onChange={e=>SetNewUserName(e.target.value)}
                        />
                        <Button style={{width:200, marginTop:30}}
                                variant="outline-secondary"
                                id="button-addon2"
                                onClick={async ()=>{
                                    await changeName()
                                    setTimeout(()=>setError(''), 1000)
                                }}
                        >
                            Сменить имя
                        </Button>
                        {user._waitingRequestChangeName===true? <Spinner animation="border"/>:<div></div>}
                        {error=='201'?<div>Имя успешно изменено</div>:<div>{error}</div>}
                    </InputGroup>
                </div>
            </div>
            <LightGrayLine/>


            <div className="d-flex flex-row justify-content-start flex-wrap mt-4">
                        <span className="text-nowrap"
                              style={{fontSize:24, width:200, marginRight:100, marginBottom:15 }}>
                            Поменять пароль
                        </span>
                <ChangePassword/>

            </div>

            <LightGrayLine/>




            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">
                <span className="text-nowrap"  style={{fontSize:24, width:200, marginRight:100, marginBottom:15 }}>
                    Удалить аккаунт
                </span>

                <div className="d-flex flex-column align-items-end" style={{width:300}}>
                    <Button
                        style={{width:200}}
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={()=>{deleteUser()}}
                    >
                        Удалить аккаунт
                    </Button>
                    {errorDelete==='200'?<div>Аккаун удалён</div>:<div>{errorDelete}</div>}
                </div>

            </div>
            <LightGrayLine/>

            </div>



    );
});

export default UserAccaunt;

//style={d.id%2 ===0?{background:"white"}:{background:"lightgrey"}}