import React from 'react';
import MessageInChat from "./MessageInChat";
import MainChatInput from "./MainChatInput";
import {observer} from "mobx-react-lite";


const MainChat = observer( () => {

    return (

        <div className="d-flex flex-column justify-content-end align-items-start mt-4 ml-2 mr-5"
             style={{width:'90%', height:580, borderStyle:"none", minWidth:360, maxWidth:720}}>
            <MessageInChat reversed={false}/>
            <MainChatInput />
        </div>

    );
});

export default MainChat;