import React, {FC} from 'react';
import {PRICE_ROUTE} from "../utils/consts";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";


const Footer:FC = () => {
    const navigate = useNavigate()
    return (
        <div className=" d-flex flex-column flex-wrap justify-content-center bottom">
            <div className="mr-5 ml-5 d-flex flex-row flex-wrap justify-content-around bottom mt-5"
                 style={{height:150,
                         borderTopStyle:"solid",
                         borderTopWidth:"1px",
                        borderColor:"lightgray"}}>
                    <div className="d-flex flex-column justify-content-start">
                        <div className="mt-3">www.pogadaytaro.ru</div>
                        <div className="mt-1" style={{maxWidth:350, fontSize:12}}>
                            Гадание онлайн на таро.<br/>
                            Самозанятый Девятериков Николай Вячеславович<br/>
                            ИНН 591114894989<br/>
                           </div>
                    </div>
            <div className="mt-3">По всем вопросам обращайтесь на:<a href="mailto:info@pogadaytaro.ru "> info@pogadaytaro.ru</a>
            </div>

            </div>
            <Button
                variant="light"
                style={{color:"gray", marginTop:50}}
                onClick={()=>navigate(PRICE_ROUTE)}
            >
                Стоимость
            </Button>
        </div>

    );
};

export default Footer;