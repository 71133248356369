import $api from "../http/forTraining";
import {AxiosResponse} from "axios";
import {
    IPrice,
} from "../models/IUser";

export default class PriceService{

    static async getAllPriceLines():Promise<AxiosResponse<IPrice[]>>{
        return $api.get('/price/getAllPriceLines')
    }


}

