import React from 'react';
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {bottom} from "@popperjs/core";
import FormOfEnter from "./FormOfEnter";
import {metrika} from "../../utils/metrika";

const Entrance = () => {
    return (
        <OverlayTrigger
            trigger="click"
            rootClose
            key={bottom}
            placement={bottom}
            overlay={
                <Popover
                    style={{width:250, offset: 400, }}
                    id={`popover-positioned-${bottom}`}>
                    <FormOfEnter/>
                </Popover>
            }
        >
        <Button
            variant="outline-secondary"
            onClick={()=>metrika('reachGoal','EnterOnMainPage')}
        >
            Войдите
        </Button>
        </OverlayTrigger>
    );
};

export default Entrance;