import React, {useContext, useEffect} from 'react';
import useSound from "use-sound";
import {Button} from "react-bootstrap";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
const sound = `${process.env.REACT_APP_STAT_URL}/task.wav`
const Sound = observer( () => {
    const [playSound] = useSound(sound)
    const {user} =useContext(Context)
    useEffect(()=>{
        if (user.getSound()!==''){
            playSound()
        }
    },[user._sound])
    return (
        <div>

        </div>
    );
});

export default Sound;