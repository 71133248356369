import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from "./store/UserStore";
import ListOfSteamsStore from "./store/ListOfSteamsStore";
import NavBarStore from "./store/NavBarStore";
import AvatarSelectedStore from "./store/AvatarSelectedStore";
import WidthStore from "./store/WidthStore";
import {WebSocketProvider} from "./store/WsContext";
import PriceStore from "./store/PriceStore";
import PrivateChatStore from "./store/PrivateChatStore";



const steam:ListOfSteamsStore = new ListOfSteamsStore()
const navbar:NavBarStore = new NavBarStore()
const avatar_selected:AvatarSelectedStore = new AvatarSelectedStore()
const width:WidthStore = new WidthStore()
const price:PriceStore = new PriceStore()
const privateChat:PrivateChatStore = new PrivateChatStore()
const user:UserStore =  new UserStore(steam, privateChat)

const initialState ={
        user,
        steam,
        navbar,
        avatar_selected,
        width,
        price,
        privateChat
}

export const Context = createContext(initialState)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <WebSocketProvider>
        <Context.Provider value={{
            user,
            steam,
            navbar,
            avatar_selected,
            width,
            price,
            privateChat
            }}>
        <App />
        </Context.Provider>
    </WebSocketProvider>

);
