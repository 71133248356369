import React, {useContext, useEffect} from 'react';
import { Pagination} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const CoinsTable = observer(() => {

    const {user} = useContext(Context)
    const pageCount = Math.ceil(user._countOfCoinsTransations / 6)
    const pages:number[] = []
    for (let i = 0; i < pageCount; i++) {
        pages.push(i+1)
    }

   useEffect(()=>{
        user.requestCoins()
    },[user._pageCoins])


    if(user._countOfCoinsTransations===0){
        return <div>Нет транзакций</div>
    }else {

    return (

        <div style={{
            maxWidth:800,
            overflowX:"auto"

        }}>
            <div>
            <div className="d-flex flex-row mb-2 mt-2" style={{fontSize:16, fontWeight:'bold', fontFamily:"Montserrat"}}>
                <div className="mt-1" style={{width:230,  textAlign:'center'}}>Дата</div>
                <div className="mt-1" style={{width:150,textAlign:'center'}}>Изменение</div>
                <div className="mt-1" style={{width:170, textAlign:'center'}}>Монет на счету</div>
                <div className="mt-1" style={{width:170, textAlign:'center'}}>Тип</div>
                <div className="mt-1" style={{width:150, textAlign:'center'}}>Номер</div>
            </div>

            <div className="d-flex flex-column">{user.getCoins().slice(0).reverse().map(d=>
                <div className="d-flex flex-row"
                     key={d.id+d.updatedAt}
                     style={{
                         borderBottomStyle:'solid',
                         borderBottomColor:'lightgrey',
                         borderBottomWidth: 0.2,
                         fontSize:14,
                         fontWeight:'normal',
                         fontFamily:"Montserrat"
                     }}>
                    <div className="mt-1" style={{width:230,  textAlign:'center'}}>{d.createdAt}</div>
                    <div className="mt-1" style={{width:150,  textAlign:'center'}}>{d.transaction}</div>
                    <div className="mt-1" style={{width:170, textAlign:'center'}}>{d.coins_count}</div>
                    <div className="mt-1" style={{width:170,  textAlign:'center'}}>{d.cause}</div>
                    <div className="mt-1" style={{width:150, textAlign:'center'}}>{d.id}</div>
                </div>
            )}</div>

            <Pagination className="mt-2" >
                <Pagination.Item
                    key='начало'
                    active={false}
                    activeLabel=''
                    onClick={()=>user.setPageCoins(1)}
                > Начало
                </Pagination.Item>
                {pages.filter((item, index, arr)=>
                {return index<(user.getPageCoins()+3)&&index>=user.getPageCoins()-3} )
                    .map(page=>
                    <Pagination.Item
                        key={page}
                        active={user._pageCoins === page}
                        activeLabel=''
                        onClick={()=>user.setPageCoins(page)}
                    >
                        {page}
                    </Pagination.Item>
                )}
                <Pagination.Item
                    key='...'
                    active={false}
                    activeLabel=''
                    onClick={()=>user.setPageCoins(user.getPageCoins()+3)}
                > ...
                </Pagination.Item>
                <Pagination.Item
                    key='конец'
                    active={false}
                    activeLabel=''
                    onClick={()=>user.setPageCoins(pageCount)}
                > Конец
                </Pagination.Item>



            </Pagination>
            </div>
        </div>
    );
    }
});

export default CoinsTable;