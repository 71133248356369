import React, {FC, useContext} from 'react';
import {Button, Image} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {metrika} from "../../../utils/metrika";


interface iCoinBay{
    count_of_coins:number,
    price: number,
    discount_in_percent: number,
    price_before_discount:number,
    description:string
}
const CoinBay:FC<iCoinBay> = observer(({count_of_coins, price,discount_in_percent,price_before_discount, description}:iCoinBay) => {

    const {user, steam} = useContext(Context)

    return (
        <div className="d-flex flex-column flex-nowrap justify-content-center" style={{color:"red", marginTop:4}}>

            <div className="d-flex flex-row flex-nowrap align-content-center justify-content-center">
                <Image className="moneta "
                       style={{zIndex:1}}
                       width={74}
                       height={74}
                       alt="Монета гадания таро по видео"
                       src={`${process.env.REACT_APP_STAT_URL}/coin.webp`}/>
                    <div className="d-flex flex-row justify-content-around align-content-around"
                         style={{zIndex:2, marginLeft:-75, width:74}}>
                        <span style={{
                        fontSize:28,
                        marginTop:15,
                        marginLeft:5,
                        color: "darkslategrey"    }}>{count_of_coins}
                        </span>
                    </div>
            </div>



            <div className="d-flex flex-row justify-content-between" style={{width:140}}>
            <div style={{color:"black", borderRadius:6}}>
                     <span
                         className="priceNow"
                         style={{fontFamily:"PT Sans", fontSize:16, fontWeight:"bold"}}>{price} &#8399;
                     </span>
            </div>


            <div style={{ color:"#707f8d", borderRadius:6, marginLeft:4}}>
                <span className="priceBefore" >{price_before_discount} &#8399; </span>
            </div>

            <div className="d-flex flex-column justify-content-center" style={{ background:"red", width:28, height:20,
                color:"white", borderRadius:6, marginTop:2, marginLeft:2}}>
                     <span
                         className="priceNow"
                         style={{fontFamily:"PT Sans", fontSize:12}}>{`-${discount_in_percent}%`}
                     </span>
            </div>
            </div>


            <Button
                onClick={()=>
                {
                    const linkToPath = steam.getSteamArr().find(s => s.witchId === steam.getCurrentWitchId())?.witchNick
                    user.buyCoins(price, description, linkToPath).then(r=>{
                        if (r.status===201){
                            window.location.replace(r.data)
                        }
                    })
                    metrika('reachGoal',`${count_of_coins}CoinsMain`)
                }}
                variant="outline-secondary">Купи</Button>
        </div>
    );
});

export default CoinBay;