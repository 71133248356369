import React, {useContext} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import AccountCurrentAmoountOfIzumrudsAndCoins from "./AccountCurrentAmoountOfIzumrudsAndCoins";
import AccountCashIn from "./AccountCashIn";
import {useNavigate} from "react-router-dom";
import {MAIN_ROUTE, USER_ACCOUNT_ROUTE} from "../../utils/consts";
import {Context} from "../../index";
import {metrika} from "../../utils/metrika";


const AccountMenu = () => {
    const {user} = useContext(Context)
    const navigate = useNavigate()
    return (
        <div className="d-flex flex-column">

        <ListGroup>
            <ListGroup.Item variant="light">
                Купить монеты
                <AccountCashIn/>
            </ListGroup.Item>
            <ListGroup.Item variant="light">
                Монет на счету
                <AccountCurrentAmoountOfIzumrudsAndCoins/>
            </ListGroup.Item>

            <ListGroup.Item action
                            onClick={()=>{
                                document.body.click()
                                metrika('reachGoal','AccountSettings')
                                navigate(USER_ACCOUNT_ROUTE)
                            }}
                            variant="light"
            >
                Настройки аккаунта
            </ListGroup.Item>
            <ListGroup.Item
                action variant="light"
                onClick={()=>{
                    navigate(MAIN_ROUTE)
                    user.logout()
                    }
            }
            >
                Выход
            </ListGroup.Item>
        </ListGroup>
        </div>
    );
};

export default AccountMenu;