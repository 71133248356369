import React, {createContext, useContext, useEffect, useState} from 'react';
import LightGrayLine from "../components/LightGrayLine/LightGrayLine";
import {Button, Form, InputGroup} from "react-bootstrap";
import AvatarSelect from "../components/AvatarSelect/AvatarSelect";
import NewPassword from "../components/NewPassword/NewPassword";
import WitchRegistrationStore from "../store/WitchRegistrationStore";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import {MAIN_ROUTE, STUDIO_ROUTE} from "../utils/consts";

const witch_registration:WitchRegistrationStore =  new WitchRegistrationStore()

const initialState ={
    witch_registration
}

export const ContextWitchReg = createContext(initialState)

const Registration = observer( () => {

const {witch_registration} = useContext(ContextWitchReg)
const {user, avatar_selected} = useContext(Context)
const [error, setError] = useState('')
const navigate = useNavigate()

useEffect(()=>{
        user.setIsInPrivateChat(false)
    },[])


const registrationWitch = async () =>{
    let accPicNum:number =1
    const selected_avatar = avatar_selected.getAvatarSelected().find(element=>element.avatar_is_selected===true)
    if (typeof selected_avatar!=="undefined"){
        accPicNum = selected_avatar.avatar_id
    }

    setError(await user.registration_witch(
        witch_registration.email,
        witch_registration.userName,
        witch_registration.password,
        witch_registration.secondPassword,
        accPicNum,
        witch_registration.description,
        witch_registration.pathLink
    ))
    await user.getWitch()
    await user.checkRefreshToken()
}
    const returnMessage= ():string[]=>{
        if (error==='201')
            return ['Успешно']
        else if (typeof error === 'string') {
            const ResArr = error.split(', ')
            return ResArr
        }
        else return ['']
    }


    return (
        <ContextWitchReg.Provider value={{witch_registration}}>
        <div className="d-flex flex-column justify-content-between ml-3 mr-5" >

            <div className="mt-4">
                <span style={{fontSize:54}}>Регистрация гадалки</span>
            </div>

            <LightGrayLine/>



            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{
                    fontSize:24,
                    width:200,
                    marginRight:100,
                    marginBottom:15}}>
                    Введите имя</span>
                <div>
                    <InputGroup className="mb-3 d-flex flex-column align-items-end">
                        <Form.Control
                            placeholder="Введите имя"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            style={{width:300}}
                            value={witch_registration.userName}
                            onChange={e=>witch_registration.setUserName(e.target.value)}
                        />
                    </InputGroup>
                </div>
            </div>
            <LightGrayLine/>


            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{
                    fontSize:24,
                    width:200,
                    marginRight:100,
                    marginBottom:15}}>
                    Введите email</span>
                <div>
                    <InputGroup className="mb-3 d-flex flex-column align-items-end">
                        <Form.Control
                            placeholder="Введите email"
                            aria-describedby="basic-addon2"
                            style={{width:300}}
                            value={witch_registration.email}
                            onChange={e=>witch_registration.setEmail(e.target.value)}
                        />
                    </InputGroup>
                </div>
            </div>
            <LightGrayLine/>

            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">
                <span className="text-nowrap"
                      style={{
                          fontSize:24,
                          width:200,
                          marginRight:100,
                          marginBottom:15 }}>
                    Придумайте пароль
                </span>
                <NewPassword/>
            </div>
            <LightGrayLine/>



            <div className="d-flex justify-content-start flex-wrap mt-4">

                <span className="text-nowrap" style={{
                    fontSize:24,
                    width:200,
                    marginRight:100,
                    marginBottom:15}}>
                    Придумайте ссылку</span>


                <div>

                <div>
                    <InputGroup className="mb-3 d-flex flex-column align-items-end">
                        <Form.Control
                            placeholder="Введите  ссылку на профиль"
                            style={{width:300}}
                            value={witch_registration.pathLink}
                            onChange={e=>witch_registration.setPathLink(e.target.value)}
                        />
                    </InputGroup>
                </div>
                    <div  style={{width:300, marginTop:10}}>
                        <p style={{fontSize:14, color:"gray"}}>Например:</p>
                        <p style={{fontSize:14, color:"gray"}}>olga</p>
                        <p style={{fontSize:14, color:"gray"}}>посетители смогут попадать на вашу страницу по ссылке www.pogadaytaro.ru/olga</p>
                    </div>
            </div>
            </div>
            <LightGrayLine/>





            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">
                <span className="text-nowrap"  style={{fontSize:24, width:200, marginRight:100, marginBottom:15 }}>
                    Выберите аватар
                </span>

                <div className="d-flex flex-column align-items-end" style={{width:300}}>
                    <AvatarSelect/>
                </div>
            </div>

            <LightGrayLine/>


            <div className="d-flex flex-row justify-content-start flex-wrap mt-4">
                        <span className="text-nowrap"
                              style={{fontSize:24, width:200, marginRight:100, marginBottom:15 }}>
                            Добавь описание
                        </span>
                <div className="d-flex flex-column">
                <textarea
                    style={{
                        width:300,
                        height:100,
                        minWidth:300,
                        minHeight:100,
                        maxWidth:300,
                        maxHeight:100, borderColor:"lightgrey"}}
                    value={witch_registration.description}
                    onChange={e=>witch_registration.setDescription(e.target.value)}
                />
                    <div style={{width:300, marginTop:10}}>
                        <p style={{fontSize:14, color:"gray"}}>
                            Максимум 500 символов. .</p>
                        <p style={{fontSize:14, color:"gray"}}>
                            Например : "Потомственная гадалка" .</p>
                    </div>
                </div>
            </div>



            <LightGrayLine/>




            <div className="d-flex d-flex justify-content-start flex-wrap mt-4">
                <span className="text-nowrap"  style={{fontSize:24, width:200, marginRight:100, marginBottom:15 }}>
                    Завершить регистрацию
                </span>

                <div className="d-flex flex-column align-items-end" style={{width:300}}>
                    <Button
                        className="d-flex flex-row justify-content-"
                        style={{width:200}}
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={async ()=>
                        {
                            registrationWitch().then(
                                ()=>{
                                    if(error==='201'){
                                        navigate(MAIN_ROUTE+'studio')
                                    }
                                })
                        }}
                    >
                        Зарегистрироваться
                    </Button>

                    <div className='mt-2 d-flex flex-column align-items-end'>
                        {returnMessage().map(m=><div key={m} className='mt-0'>{m}</div>)}
                    </div>
                    {error=='201'?
                        <div>
                            <h4>Вы успешно зарегистрированы</h4>
                        <Button
                            onClick={()=>{
                                    navigate(STUDIO_ROUTE)
                                }}>
                            Перейдите в кабинет гадания
                        </Button>
                        </div>:null}
                </div>
            </div>
        </div>
        </ContextWitchReg.Provider>
    );
});

export default Registration;