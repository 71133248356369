import React, {useContext, useState} from 'react';
import {Button, Form, InputGroup, Spinner} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const ChangePassword = observer(() => {
    const {user} = useContext(Context)
    const [password, SetPassword] = useState('')
    const [newPassword1, SetNewPassword1] = useState('')
    const [newPassword2, SetNewPassword2] = useState('')
    const [error, setError] = useState('')

    const changePassword= async ()=> {
        setError(await user.changePassword(password,newPassword1,newPassword2))
    }

    return (
        <div>
            <InputGroup className="mb-3 d-flex flex-column align-items-end">
                <Form.Control
                    placeholder="Старый пароль"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{width:300}}
                    type="password"
                    value={password}
                    onChange={e=>SetPassword(e.target.value)}
                />
                <Form.Control
                    placeholder="Новый пароль"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{width:300, marginTop:30}}
                    type="password"
                    value={newPassword1}
                    onChange={e=>SetNewPassword1(e.target.value)}
                />
                <div style={{width:300, marginTop:10}}>
                    <p style={{fontSize:14, color:"gray"}}>Надежность пароля:</p>
                    <p style={{fontSize:14, color:"gray"}}>Минимальная длина пароля – 4 символа. Не используйте пароли от
                        других сайтов или варианты, которые
                        злоумышленники смогут легко подобрать.</p>
                </div>
                <Form.Control
                    placeholder="Подтвердите новый пароль"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{width:300, marginTop:10}}
                    type="password"
                    value={newPassword2}
                    onChange={e=>SetNewPassword2(e.target.value)}
                />
                <Button style={{width:200, marginTop:30}}
                        variant="outline-secondary" id="button-addon2"
                        onClick={async ()=>{
                            await changePassword()
                            setTimeout(()=>setError(''), 1000)
                        }}
                >
                    Сменить пароль
                </Button>
                {user._waitingRequestChangePassword==true? <Spinner animation="border"/>:<div></div>}
                {error=='201'?<div style={{maxWidth:'30',wordBreak:'break-all'}}>
                        Пароль успешно изменён
                    </div>:
                    <div style={{maxWidth:290,overflowWrap:'break-word'}}>{error}</div>}
            </InputGroup>


        </div>
    );
});

export default ChangePassword;