import React, {useContext, useEffect} from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer";
import Width from "./components/Width/Width";
import {Context} from "./index";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {WebSocketContext} from "./store/WsContext";
import Sound from "./service/Sound";

const App = () => {
    const {user,avatar_selected, steam, privateChat} = useContext(Context)
    const {ws, onJoinroom} = useContext(WebSocketContext)
    useEffect(()=>{
       user.getWitch()
       user.checkAuth().then(()=>{
           ws.emit('onLeftRoom')
           if (user.getIsInPrivateChat()){
          if (user.getIsAuth()){
              ws.emit('JoinPrivateRoom',{privateRoomLink:privateChat.getRoom(), userOrWitchId:user.getUser().userId})
          }
            }

          if (user.getIsAuth()){
              if (typeof steam.getCurrentWitchId()==='number'){
                  ws.emit('JoinRoom',{witchId:steam.getCurrentWitchId(), userId:user.getUser().userId})
              }else {
                  ws.emit('JoinRoom',{witchId:user.getUser().userId, userId:user.getUser().userId})
              }

          }else {
              if (typeof steam.getCurrentWitchId()==='number'){
                  ws.emit('JoinRoom',{witchId:steam.getCurrentWitchId(), userId:91})
              }else {
                  ws.emit('JoinRoom',{witchId:91, userId:91})
              }
          }


})
if(localStorage.getItem('token')){
          avatar_selected._avatarSelected.map(a=>a.avatar_id===user.getUser().userpicId?
          a.avatar_is_selected=true:
          a.avatar_is_selected=false)}
// pingWs()
}, [])



  return (

      <BrowserRouter>

              <AppRouter/>

              <ToastContainer />
              <Sound/>
      </BrowserRouter>
  );


};

export default App;

