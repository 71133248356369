import React, {useContext} from 'react';
import {Form, InputGroup} from "react-bootstrap";
import {ContextWitchReg} from "../../Pages/registration";
import {observer} from "mobx-react-lite";

const NewPassword = observer(() => {
    const {witch_registration} = useContext(ContextWitchReg)
    return (
        <div>
            <InputGroup className="mb-3 d-flex flex-column align-items-end">
                <Form.Control
                    placeholder="Придумайте пароль"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{width:300, marginTop:30}}
                    type="password"
                    value={witch_registration.password}
                    onChange={e=>witch_registration.setPassword(e.target.value)}
                />
                <div style={{width:300, marginTop:10}}>
                    <p style={{fontSize:14, color:"gray"}}>Надежность пароля:</p>
                    <p style={{fontSize:14, color:"gray"}}>Минимальная длина пароля – 8 символов. Не используйте пароли от
                        других сайтов или варианты, которые
                        злоумышленники смогут легко подобрать.</p>
                </div>
                <Form.Control
                    placeholder="Подтвердите пароль"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{width:300, marginTop:10}}
                    type="password"
                    value={witch_registration.secondPassword}
                    onChange={e=>witch_registration.setSecondPassword(e.target.value)}
                />
            </InputGroup>
        </div>
    );
});

export default NewPassword;