import $api from "../http/forTraining";
import {AxiosResponse} from "axios";
import {AuthRefreshResponse, AuthResponse} from "../models/response/AuthResponse";


export default class AuthService{
    static async login(email:string, password: string):Promise<AxiosResponse<AuthRefreshResponse>>{
        return $api.post<AuthRefreshResponse>('auth/local/singin',{email, password})
    }

    static async registration(email:string, password: string):Promise<AxiosResponse<AuthRefreshResponse>>{
        return $api.post<AuthRefreshResponse>('auth/local/singup',{email, password})
    }

    static async randomRegistration():Promise<AxiosResponse<AuthRefreshResponse>>{
        return $api.post<AuthRefreshResponse>('auth/local/randomsingup',)
    }

    static async registration_witch (email:string,userName:string,password:string,secondPassword:string,
    accountPicNumber:number,description:string, pathLink:string):Promise<AxiosResponse<AuthRefreshResponse>>{
        return $api.post<AuthRefreshResponse>('auth/local/singupWitch',{email, userName, password,secondPassword,
            accountPicNumber, description, pathLink})
    }

    static async logout():Promise<void>{
        return $api.post('auth/logout')
    }

    static async checkAuth():Promise<AxiosResponse<AuthResponse>>{
        return $api.get('auth/checkAuth')
    }

}