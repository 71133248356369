import React from 'react';
import MobileMessageInChat from "../mobileChatButton/MobileMessageInChat";
import MobileMainChatInput from "../mobileChatButton/MobileMainChatInput";

const MobilePrivateChat = () => {
    return (
        <div className="d-flex flex-column justify-content-end align-items-start mt-4 ml-2 mr-5"
             style={{
                 width:"95%",
                 height:"20vh",
                 maxWidth:720}}>

            <MobileMessageInChat reversed={false}/>
            <MobileMainChatInput />
        </div>
    );
};

export default MobilePrivateChat;