import React, {useContext, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import './Registration.css'
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {WebSocketContext} from "../../store/WsContext";
import {metrika} from "../../utils/metrika";

const Registration = observer(() => {
    const {user} = useContext(Context)
    //const [show, setShow] = useState(false);
    const handleClose = () => user.setRegistrationModalShow(false);
    const handleShow = () => user.setRegistrationModalShow(true);
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState([])
    const [alreadyRegistred, SetAlreadyRegistred] = useState<boolean>(false)
    const {ws, onLeftRoom, onJoinroom  } = useContext(WebSocketContext)

   const singUp = async ()=>{
       setError(await user.registration(email,password))
       ws.io.opts.query ={
           jwtTokenFieldName:localStorage.getItem('token')
       }
       ws.disconnect().connect()
       onLeftRoom()
       onJoinroom()
    }


    const singIn = async ()=>{
        setError(await user.login(email,password))
        ws.io.opts.query ={
            jwtTokenFieldName:localStorage.getItem('token')
        }
        ws.disconnect().connect()
        onLeftRoom()
        onJoinroom()
    }


    const changeAlreadyRegistred = ()=>{
        SetAlreadyRegistred(!alreadyRegistred)
    }

    return (
        <>
        <Button variant="outline-secondary"
                className="registration text-nowrap"
                onClick={()=>{
                    handleShow()
                    metrika('reachGoal','RegistrationOnMainPage')
                }}>
            Зарегистрируйся и получи бонус на гадание
        </Button>

            <Modal show={user.getRegistrationModalShow()} onHide={handleClose}>

                <Modal.Body>
                    <h4 style={{width:'100%', textAlign:"center", marginBottom:30}}>
                        {!alreadyRegistred?<div>Зарегистрируйся и получи бонус на гадание</div>:<div>Войдите</div>}
                    </h4>
                    <Form>


                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Введите email:</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email..."
                                value={email}
                                onChange={e=>setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>
                                {!alreadyRegistred?<div> Придумайте пароль:</div>:<div>Введите пароль:</div>}


                            </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Придумайте пароль..."
                                value={password}
                                onChange={e=>setPassword(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                   {typeof error==='string'?<p>{error}</p>:error.map(m=><p key={m}>{m}</p>)}

                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex flex-column align-items-end'>
                    <div>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={()=>{
                        if(!alreadyRegistred) {
                                singUp()
                                metrika('reachGoal', 'Registration')
                            }
                        else
                            {
                                singIn()
                            }
                        }
                    }>
                        {!alreadyRegistred?<div>Зарегистрироваться</div>:<div>Войдите</div>}
                    </Button>
                    </div>

                    <div>
                    <Button
                        onClick={changeAlreadyRegistred}
                        style={{
                            textDecoration:"underline",
                            background:"none",
                            borderStyle:"none",
                            color:"black",
                            marginTop:10,
                            fontSize:12
                        }}>
                        {!alreadyRegistred?<div> Уже зарегистрированы?</div>:<div>На страницу регистрации</div>}

                    </Button>
                    </div>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    );
});

export default Registration;