import React, {FC, useContext} from 'react';
import {ListGroup} from "react-bootstrap";
import {Context} from "../../index";
import {useNavigate} from "react-router-dom";
import {MAIN_ROUTE} from "../../utils/consts";

interface iListSearch{
    searchText : string
}


const ListOfSearch:FC<iListSearch>= ({searchText}) => {
    const navigate = useNavigate()
    const {steam} = useContext(Context)

    if (steam.getSortedWitches()
        .filter(e => e.witchName.toLowerCase().includes(searchText.toLowerCase())).length === 0)
    {
        return (
            <ListGroup>
            <ListGroup.Item key='nothing'>Ничего не найдено</ListGroup.Item>
            </ListGroup>    )
    } else
    {
        return ( <ListGroup>
            {steam.getSortedWitches()
                .filter(e=>e.witchName
                .toLowerCase()
                .includes(searchText.toLowerCase()))
                .slice(0,4)
                .map(w=>
                    <ListGroup.Item
                        onClick={()=>{
                            //onLeftRoom()
                            navigate(MAIN_ROUTE +w.witchNick)
                            document.body.click()}}
                        action
                        key={w.witchId}>
                            {w.witchName}
                    </ListGroup.Item>)
            }
                </ListGroup>  )
    }

}


export default ListOfSearch;