import React from 'react';
import { Nav } from 'react-bootstrap';
import {Button, Container, Form} from "react-bootstrap";
import {REGISTRATION_ROUTE} from "../utils/consts";
import {useNavigate} from "react-router-dom";

const Auth = () => {
    const navigate = useNavigate()
    return (
        <Container
            className="d-flex flex-column justify-content-center align-items-center"
            style={{height: window.innerHeight -54, background:"none"}}>
            <h2 className="m-4">{'Авторизация'}</h2>
            <Form className="d-flex flex-column" style={{width:320}}>

                <div className="d-flex mt-3 d-flex justify-content-between">
                    <div className="d-flex flex-column align-items-start">
                        <div>Нет аккаунта?</div>
                        <Nav.Link style={{padding:0}} onClick={()=>navigate(REGISTRATION_ROUTE)}>Зарегистрируйтесь!</Nav.Link>
                    </div>
                    <div  className="justify-content-end">
                        <Button  variant={"outline-secondary"}> Войти</Button>
                    </div>
                </div>

            </Form>

        </Container>
    );
};

export default Auth;