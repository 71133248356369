import React, {useContext, useEffect, useState} from 'react';
import {Button, Spinner} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../../index";
import {WebSocketContext} from "../../../store/WsContext";
import {IMessage} from "../../../models/IMessage";
import {MAIN_ROUTE} from "../../../utils/consts";
import WitchPrivateComponent from "../../PrivateComponent/WitchPrivateComponent";
import MainChatReverse from "../../MainChat/MainChatReverse";
import UserPrivateComponent from "../../PrivateComponent/UserPrivateComponent";
import MobileWitchPrivateComponent from "./MobilePrivateComponent/MobileWitchPrivateComponent";
import MobileUserPrivateComponent from "./MobilePrivateComponent/MobileUserPrivateComponent";

export interface IPrivateChannels{
    witchChannel:string,
    userChannel:string
}
const MobilePrivateGadanie = observer(() => {
          const {user,steam, privateChat} = useContext(Context)
          const {onJoinPrivateRoom, onLeftRoom} = useContext(WebSocketContext)
          const [isWitch, SetIsWitch] = useState(false)
          const [loaded,SetLoaded] = useState(false)
          const [areYouInRoom,setAreYouInRoom] = useState(false)
          const [loadingAreYouInRoom,setLoadingAreYouInRoom] = useState(true)

          const navigate = useNavigate()
          const {privateRoomId} = useParams()



          const privateChannel ={
              witchChannel:'witchChannel',
              userChannel:'userChannel'
          } as IPrivateChannels

         useEffect(()=>{
              user.setIsInPrivateChat(true)
              steam.setMessagesInChat([] as IMessage[])
              onLeftRoom()
              user.checkAuth()
              user.getWitch()
              //console.log('privateRoomId: ')
             // console.log(privateRoomId)
              privateChat.checkIsUserOrWitchInRoom(privateRoomId).then(res=>{
                  if(typeof privateRoomId==='string')privateChat.setRoom(privateRoomId)
              //    console.log('res.data.areYouInRoom: ')
              //    console.log(res.data.areYouInRoom)
                  setAreYouInRoom(res.data.areYouInRoom)
                  setLoadingAreYouInRoom(false)
             })
          },[])


          useEffect(()=>{

                  if(user._waitingRequestWitch===false && typeof user.getUser().userId ==='number'){
                      steam.setCurrentWitchId(Number(privateRoomId))
                      onJoinPrivateRoom(privateRoomId,user.getUser().userId)
                      SetLoaded(true)
                      const witch = steam.getSteamArr().filter(e=>e.witchId===user.getUser().userId)[0]
                      if (witch) SetIsWitch(true)
                  }
              }
              ,[steam._steamArr,user._user.userId])

          useEffect(() => {
              if (user._isLoading===false){
                  if(user._isAuth===false){
                      navigate(MAIN_ROUTE)
                  }
                  if (loadingAreYouInRoom===false){
                      if(areYouInRoom===false){
                          navigate(MAIN_ROUTE)
                      }
                  }
              }
          },[user._isAuth,user._isLoading])


          if (!loaded){
              return <Spinner></Spinner>
          }else {

              if(isWitch){
                  return (
                      <div
                          className='d-flex flex-column'
                          style={{
                              overflow:"hidden",
                              zIndex:5,
                              position:"absolute",
                              left:0,
                              width:"100%"
                          }}
                      >
                          <Button
                              style={{
                                  position:"absolute",
                                  top:0,
                                  width:"100%",
                                  zIndex:4
                              }}
                              onClick={()=>navigate(MAIN_ROUTE+ 'studio')}
                          >Вернуться в студию</Button>
                      <MobileWitchPrivateComponent witchChannel={privateRoomId+privateChannel.witchChannel} userChannel={privateRoomId+privateChannel.userChannel}/>
                      </div>
                  );
              }else
              {
                  return (
                      <div
                          className='d-flex flex-column'
                          style={{
                              overflow:"hidden",
                              zIndex:5,
                              position:"absolute",
                              left:0,
                              width:"100%"
                          }}
                      >
                          <Button
                              style={{
                                  position:"absolute",
                                  top:0,
                                  width:"100%",
                                  zIndex:4
                              }}
                              onClick={()=>navigate(MAIN_ROUTE)}
                          >Выйти из комнаты</Button>
                      <MobileUserPrivateComponent witchChannel={privateRoomId+privateChannel.witchChannel} userChannel={privateRoomId+privateChannel.userChannel}/>
                      </div>
                  )
              }

          }


      }
)


export default MobilePrivateGadanie;