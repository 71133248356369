import React, {FC} from 'react';
import {Image} from "react-bootstrap";

interface IViewers {
    fontSize:number,
    viewersCount:number
}

const Viewers:FC<IViewers> = ({fontSize,viewersCount} ) => {
    return (
        <div>
            <span style={{fontSize:fontSize}}>{viewersCount}</span>
            <Image style={{color:"white"}} alt="Гадание таро по видео" className="ml-1 mr-2 mb-2" width={20} height={20} src={`${process.env.REACT_APP_STAT_URL}/viewers.png`} />
        </div>
    );
};

export default Viewers;